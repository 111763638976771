import loanRequestService from 'src/services/loanRequestService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const SET_CUSTOMER = '@loanRequest/set-customer-loan-request';
export const SET_FORM_CREATE = '@loanRequest/set-form-create-loan-request';
export const SET_FORM_EDIT = '@loanRequest/set-form-edit-loan-request';
export const SET_PRODUCT_LOAN_REQUEST = '@loanRequest/set-product-loan-request';
export const CLEAN_LOAN_REQUEST = '@loanRequest/clean-loan-request';
export const SET_FORM_CODEBTOR = '@loanRequest/set-form-create-codebtor-loan-request';
export const SET_ADDITIONAL_INFO = '@loanRequest/set-additional-info-loan-request';
export const SET_PRODUCTS_INFO = '@loanRequest/set-products-info-loan-request';
export const SET_LOAN_REQUEST_VALIDATIONS = '@loanRequest/set-validations-of-the-loan-request';
export const SET_SELECT_LOANS = '@loanRequest/set-select-loan';
export function setCustomerLoanRequest(values) {
  const { originatorId, identification, result } = values;
  return {
    type: SET_CUSTOMER,
    customer: {
      originatorId,
      identification,
      entityId: result.data.entityId,
      name: result.data.name,
      availableAmount: result.data?.revolvingCredit?.availableAmount ?? '',
      maxCreditAmount: result.data?.revolvingCredit?.maxCreditAmount ?? '',
      email: result.data.emailAddress,
      customerType: result.data?.customerType,
    },
  };
}

export function setLoanRequestFormCreate(values) {
  return async (dispatch) => {
    const payload = {
      amount: values.amount,
      term: values.term,
      PaymentFrequency: values.PaymentFrequency,
      PaymentStartDate: values.PaymentStartDate,
      PaymentInstallments: values.PaymentInstallments,
      loanProductId: values.loanProductId,
      gracePeriodApplyFor: values.gracePeriodApplyFor,
      cosignerIdentificationNumber: values.cosignerIdentificationNumber,
      rate: values.rate,
      DueDate: values.DueDate,
      requestType: values.requestType,
      associatedRequestId: values.associatedRequestId,
      identification: values.identificationDebtorPrincipal,
      name: values.nameDebtorPrincipal,
      downPayment: values.downPayment,
      disbursementDate: values.disbursementDate,
    };
    dispatch({ type: SET_FORM_CREATE, payload });
  };
}

export function setLoanRequestFormEdit(payload) {
  return {
    type: SET_FORM_EDIT,
    payload,
  };
}

export function setProductLoanRequest(product) {
  return {
    type: SET_PRODUCT_LOAN_REQUEST,
    product,
  };
}

export function setClearLoanRequest() {
  return {
    type: CLEAN_LOAN_REQUEST,
  };
}

// Create
export const getPersonByIdentificationNumber = (
  identification,
  originatorId,
  loanProductId,
) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const result = await loanRequestService.GetPersonByIdentificationNumber(
        identification,
        originatorId,
        loanProductId,
      );
      dispatch(
        setCustomerLoanRequest({ originatorId, identification, result }),
      );
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(finishLoading());
    } finally {
      dispatch(finishLoading());
    }
  };

export function setPrincipalLoanRequest(payload) {
  return {
    type: SET_FORM_CODEBTOR,
    payload,
  };
}

/// Approve or reject loan request
export const approveOrRejectLoanRequest = ({ loanRequest, NewStatus, comment }) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const loanRequestDTO = {
        NewStatus,
        Comment: comment,
        NewTypeRequest:
          loanRequest.requestType === null ? 1 : loanRequest.requestType,
        NewAssociatedRequestId: loanRequest.associatedRequestId,
        newAmount: loanRequest.amount,
        Frequency: loanRequest.paymentFrequency,
        paymentStartDate: loanRequest.paymentStartDate,
        PaymentTerm: loanRequest.paymentTerm,
        GracePeriod: loanRequest.gracePeriod,
        GracePeriodApplyFor: loanRequest.gracePeriodApplyFor,
        CapitalizeGracePeriod: loanRequest.capitalizeGracePeriod,
        NumberOfInstallments: loanRequest.paymentInstallments,
        NewRate: loanRequest.interestRate,
        NewNIR: loanRequest.nominalInterestRate,
        ExpirationDate: new Date(),
        downPayment: loanRequest.downPayment,
        loanProductId: loanRequest.loanProductId,
      };
      const response = await loanRequestService.RejectOrAproveLoan(
        loanRequest.id,
        loanRequestDTO,
      );
      dispatch(setMessageSnackbar(response, 'success'));
      window.location.reload();
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

// Additional Information Loan Request
export const setAdditionalInformationLoanRequest = (additionalInfo) => ({
    type: SET_ADDITIONAL_INFO,
    additionalInfo,
  });

export const getAdditionalInformation = (loanRequestId) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await loanRequestService.GetAdditionalInfoLoanRequest(
        loanRequestId,
      );
      const additionalInfo = response?.data?.map((additionalInf) => ({
        idAdditionalInfo: additionalInf.id,
        label: additionalInf.label,
        value: additionalInf.value,
        loanRequestId: additionalInf.loanRequestId,
      }));
      dispatch(
        setAdditionalInformationLoanRequest({ additionalInfo, loaded: true }),
      );
    } catch {
      dispatch(setAdditionalInformationLoanRequest({ loaded: true }));
    } finally {
      dispatch(finishLoading());
    }
  };

export const editAdditionalInformation = (additionalInformationLoanRequest, idAdditionalInfo, loanRequestId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const EditAdditionalInfoDTO = {
        id: idAdditionalInfo,
        label: additionalInformationLoanRequest.name,
        value: additionalInformationLoanRequest.value,
        loanRequestId,
      };
      const response = await loanRequestService.EditAdditionalInfoLoanRequest(
        idAdditionalInfo,
        EditAdditionalInfoDTO,
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getAdditionalInformation(loanRequestId));
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

export const createAdditionalInformation = (additionalInformationLoanRequest, loanRequestId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const AdditionalInfoDTO = {
        label: additionalInformationLoanRequest.name,
        value: additionalInformationLoanRequest.value,
        loanRequestId,
      };
      const response = await loanRequestService.CreateAdditionInfoLoanRequest(
        AdditionalInfoDTO,
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getAdditionalInformation(loanRequestId));
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

export const deleteAdditionalInformation = (idAdditionalInfo, loanRequestId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await loanRequestService.DeleteAdditionalInfoLoanRequest(
        idAdditionalInfo,
      );
      dispatch(getAdditionalInformation(loanRequestId));
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

/// Comment loan request
export const commentLoanRequest = (loanRequestId, comment) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const commentLogDTO = {
        loanRequestId,
        comment,
      };
      const response = await loanRequestService.CreateCommentLoanRequest(
        commentLogDTO,
      );
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

// Products Information Loan Request

export const setProductsInformationLoanRequest = (productsInfo) => ({
    type: SET_PRODUCTS_INFO,
    productsInfo,
  });

export const getProductsInformation = (loanRequestId) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await loanRequestService.GetProductsInfoLoanRequest(
        loanRequestId,
      );
      const productsInfo = response?.data?.map((productInfo) => ({
        idProduct: productInfo.id,
        loanRequestId,
        productType: productInfo.productType,
        idInsurance: productInfo.productInsurance?.id,
        loanRequestProductId:
          productInfo.productInsurance?.loanRequestProductId,
        insuranceCompany: productInfo.productInsurance?.insuranceCompany,
        policyType: productInfo.productInsurance?.policyType,
        policyName: productInfo.productInsurance?.policyName,
        policyNumber: productInfo.productInsurance?.policyNumber,
        beneficiary: productInfo.productInsurance?.beneficiary,
        valuePolicyVatIncluded:
          productInfo.productInsurance?.valuePolicyVatIncluded,
        beneficialOwner: productInfo.productInsurance?.beneficialOwner,
        effectiveDate: productInfo.productInsurance?.effectiveDate,
        idVehicle: productInfo.productVehicle?.id,
        loanRequestProductVechicleId:
          productInfo.productVehicle?.loanRequestProductId,
        yearVehicle: productInfo.productVehicle?.year,
        brandVehicle: productInfo.productVehicle?.brand,
        modelVehicle: productInfo.productVehicle?.model,
        vinNumberVehicle: productInfo.productVehicle?.vinNumber,
        equipmentValueVechicle: productInfo.productVehicle?.equipmentValue,
        downPaymentVechicle: productInfo.productVehicle?.downPayment,
        loanAmountVechicle: productInfo.productVehicle?.loanAmount,
        referenceVehicle: productInfo.productVehicle?.reference,
        canonMonthlyVehicle: productInfo.productVehicle?.canonMonthly,
        closing: productInfo.productVehicle?.closing,
      }));
      dispatch(
        setProductsInformationLoanRequest({ productsInfo, loaded: true }),
      );
    } catch {
      dispatch(setProductsInformationLoanRequest({ loaded: true }));
    } finally {
      dispatch(finishLoading());
    }
  };

export const editProductsInformation = (productInformationLoanRequest, idProductInfo, loanRequestId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const EditProductInfoDTO = {
        id: productInformationLoanRequest.loanRequestProductId,
        loanRequestId,
        productType: productInformationLoanRequest.productType,
        ProductInsuranceDTO: {
          id: idProductInfo,
          LoanRequestProductId:
            productInformationLoanRequest.loanRequestProductId,
          insuranceCompany: productInformationLoanRequest.insuranceCompany,
          policyType: productInformationLoanRequest.policyType,
          policyName: productInformationLoanRequest.policyName,
          policyNumber: productInformationLoanRequest.policyNumber,
          beneficiary: productInformationLoanRequest.beneficiary,
          valuePolicyVatIncluded:
            productInformationLoanRequest.valuePolicyVatIncluded,
          beneficialOwner: productInformationLoanRequest.beneficialOwner,
          effectiveDate: productInformationLoanRequest.effectiveDate,
        },
        ProductVehicleDTO: {
          id: idProductInfo,
          LoanRequestProductId:
            productInformationLoanRequest.loanRequestProductId,
          year: productInformationLoanRequest.yearVehicle,
          brand: productInformationLoanRequest.brandVehicle,
          model: productInformationLoanRequest.modelVehicle,
          vinNumber: productInformationLoanRequest.vinNumberVehicle,
          equipmentValue: productInformationLoanRequest.equipmentValueVechicle,
          downPayment: productInformationLoanRequest.downPaymentVechicle,
          closing: productInformationLoanRequest.closing,
          loanAmount: productInformationLoanRequest.loanAmountVechicle,
          reference: productInformationLoanRequest.referenceVehicle,
          canonMonthly: productInformationLoanRequest.canonMonthlyVehicle,
        },
      };
      const response = await loanRequestService.EditProductInfoLoanRequest(
        idProductInfo,
        EditProductInfoDTO,
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getProductsInformation(loanRequestId));
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

export const createProductsInformation = (productInformationLoanRequest, loanRequestId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const ProductInfoDTO = {
        loanRequestId,
        productType: productInformationLoanRequest.productType,
        ProductInsuranceDTO: {
          insuranceCompany: productInformationLoanRequest.insuranceCompany,
          policyType: productInformationLoanRequest.policyType,
          policyName: productInformationLoanRequest.policyName,
          policyNumber: productInformationLoanRequest.policyNumber,
          beneficiary: productInformationLoanRequest.beneficiary,
          valuePolicyVatIncluded:
            productInformationLoanRequest.valuePolicyVatIncluded,
          beneficialOwner: productInformationLoanRequest.beneficialOwner,
          effectiveDate: productInformationLoanRequest.effectiveDate,
        },
        ProductVehicleDTO: {
          year: productInformationLoanRequest.yearVehicle,
          brand: productInformationLoanRequest.brandVehicle,
          model: productInformationLoanRequest.modelVehicle,
          vinNumber: productInformationLoanRequest.vinNumberVehicle,
          equipmentValue: productInformationLoanRequest.equipmentValueVechicle,
          downPayment: productInformationLoanRequest.downPaymentVechicle,
          closing: productInformationLoanRequest.closing,
          loanAmount: productInformationLoanRequest.loanAmountVechicle,
          reference: productInformationLoanRequest.referenceVehicle,
          canonMonthly: productInformationLoanRequest.canonMonthlyVehicle,
        },
      };
      const response = await loanRequestService.CreateProductInfoLoanRequest(
        ProductInfoDTO,
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getProductsInformation(loanRequestId));
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

export const deleteProductsInformation = (idProductInfo, loanRequestId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await loanRequestService.DeleteProductInfoLoanRequest(
        idProductInfo,
      );
      dispatch(getProductsInformation(loanRequestId));
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
const setLoanRequestValidations = (validations) => ({
  type: SET_LOAN_REQUEST_VALIDATIONS,
  validations: {
    loaded: true,
    validations,
  },
});

export const getLoanRequestValidations = (loanRequestId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanRequestService.GetLoanRequestValidations(
      loanRequestId,
    );
    dispatch(setLoanRequestValidations(response));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(setLoanRequestValidations());
  } finally {
    dispatch(finishLoading());
  }
};

export const updateLoanRequestValidation = (validation) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const validationDTO = new FormData();
    validationDTO.append('id', validation.id);
    validationDTO.append('comment', validation.comment);
    validationDTO.append('checked', validation.checked);
    validationDTO.append('loanRequestId', validation.loanRequestId);
    validationDTO.append(
      'loanProductValidationId',
      validation.loanProductValidationId,
    );

    if (validation.files?.length > 0) {
      validation.files.forEach((file) => {
        validationDTO.append('files', file);
        validationDTO.append('PathFile', file.path);
      });
    }
    const response = await loanRequestService.UpdateLoanRequestValidation(
      validationDTO,
    );
    dispatch(setLoanRequestValidations(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(setLoanRequestValidations());
  } finally {
    dispatch(finishLoading());
  }
};

export const deleteLoanRequestValidationAttachment = (validation) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const { id, entityAttachment } = validation;
      const { pathFile, name } = entityAttachment;
      const response = await loanRequestService.DeleteLoanRequestValidationAttachment(
          id,
          pathFile.split('/').join('%2F'),
          name,
        );
      dispatch(setLoanRequestValidations(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(setLoanRequestValidations());
    } finally {
      dispatch(finishLoading());
    }
  };

export const sendNewCodes = (requestSignature) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanRequestService.SendNewCodes(requestSignature);
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const requestSignatureDocusign = (loanRequestId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanRequestService.RequestSignatureDocusign(
      loanRequestId,
    );
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const setSelectedListLoans = (selectLoans) => ({
    type: SET_SELECT_LOANS,
    selectLoans,
  });
