import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Bell as BellIcon, MessageCircle as MessageIcon } from 'react-feather';
import { getPendingTasks } from 'src/store/actions/taskActions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { tasksStatusList } from 'src/constants';
import screenRoutes from 'src/config/screen.routes';
import CompleteTask from './Components/CompleteTask';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
  },
  item: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  listItem: {
    'padding-top': 0,
    'padding-bottom': 0,
    'padding-right': 4,
    cursor: 'pointer',
  },
  line: {
    display: 'block',
  },
}));

function Notifications() {
  const classes = useStyles();
  const { pendingTasks } = useSelector((state) => state.tasks);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [completeDialog, setCompleteDialog] = useState();
  const [selectedTask, setSelectedTask] = useState('');
  const { t } = useTranslation();

  const setTaskToComplete = (task) => {
    setCompleteDialog(true);
    setSelectedTask(task);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getStatusName = (status) => tasksStatusList.find((task) => task.value === status)?.name || 'undefined';
  useEffect(() => {
    if (!pendingTasks.isSetted) {
      dispatch(getPendingTasks());
    }
  }, [pendingTasks]);

  return (
    <>
      <Tooltip title={t('notifications')}>
        <IconButton ref={ref} color="#7a7b74" onClick={handleOpen} size="large">
          <Badge
            badgeContent={
              pendingTasks.pendingTasks.length === 0 ? undefined : pendingTasks.pendingTasks.length
            }
            color="error"
            overlap="rectangular"
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography color="textPrimary" variant="h5">
            {t('notifications')}
          </Typography>
        </Box>
        {pendingTasks?.pendingTasks.length === 0 ? (
          <>
            <Box p={2}>
              <Typography color="textPrimary" variant="h6">
                {t('thereAreNoNotifications')}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {pendingTasks?.pendingTasks?.map((notification) => (
                <ListItem
                  key={notification.id}
                  className={classes.listItem}
                  divider
                  onClick={() => {
                    setTaskToComplete(notification);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.icon}>
                      <SvgIcon fontSize="small">
                        <MessageIcon />
                      </SvgIcon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.item}
                    primary={<>{notification.name.substring(0, 25)}</>}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      color: 'textPrimary',
                    }}
                    secondary={(
                      <>
                        <span className={classes.line}>
                          {notification.description}
                        </span>
                        <span className={classes.line}>
                          {`${t(getStatusName(notification.status))} - ${moment(
                            notification.dueDate,
                          ).fromNow()}`}
                        </span>
                      </>
                    )}
                  />
                </ListItem>
              ))}
            </List>
            <Box display="flex" justifyContent="center" p={1}>
              <Button
                component={RouterLink}
                onClick={handleClose}
                size="small"
                to={`${screenRoutes.tasks.list}`}
              >
                {t('viewAllTasks')}
              </Button>
            </Box>
          </>
        )}
      </Popover>
      {completeDialog ? (
        <div>
          <CompleteTask
            close={setCompleteDialog}
            onClose={() => setCompleteDialog(false)}
            open={completeDialog}
            task={selectedTask}
            title={`${t('completeTask')}`}
          />
        </div>
      ) : null}
    </>
  );
}

export default Notifications;
