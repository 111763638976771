import loanService from 'src/services/loanService';
import loanTransactionService from 'src/services/loanTransactionService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const SET_LOAN_VALIDATIONS = '@loan/set-loan-validations';
export const SET_LOAN_ENTITY = '@loan/set-loan-entity';
export const SET_LOAN_TRANSACTIONS = '@loan/set-loan-transactions';
export const SET_DISBURSEMENT_OPEN = '@loan/set-disbursement-open';

export const setDisburseDialogOpen = (open) => ({
  type: SET_DISBURSEMENT_OPEN,
  open,
});

export const setLoanValidations = (validations) => ({
  type: SET_LOAN_VALIDATIONS,
  validations,
});

export const setLoanEntity = (loanEntity) => ({
  type: SET_LOAN_ENTITY,
  loanEntity,
});

export const setLoanTransactions = (transactions) => ({
  type: SET_LOAN_TRANSACTIONS,
  transactions,
});

/// Validations
export const getLoanValidations = (loanId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanService.GetLoanValidations(loanId);
    dispatch(setLoanValidations(response));
  } catch (e) {
    dispatch(setLoanValidations([]));
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const updateLoanValidation = (validation) => async (dispatch) => {
  try {
    dispatch(startLoading());

    const validationDTO = new FormData();
    validationDTO.append('id', validation.id);
    validationDTO.append('comment', validation.comment);
    validationDTO.append('checked', validation.checked);
    validationDTO.append('loanId', validation.loanId);
    validationDTO.append(
      'loanProductValidationId',
      validation.loanProductValidationId,
    );

    if (validation.files?.length > 0) {
      validation.files.forEach((file) => {
        validationDTO.append('files', file);
        validationDTO.append('PathFile', file.path);
      });
    }

    const response = await loanService.UpdateLoanValidation(validationDTO);
    dispatch(setLoanValidations(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};
export const deleteLoanValidationAttachment = (validation) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const { id, entityAttachment } = validation;
    const { pathFile, name } = entityAttachment;
    const response = await loanService.DeleteLoanValidationAttachment(
      id,
      pathFile.split('/').join('%2F'),
      name,
    );
    dispatch(setLoanValidations(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

/// Loan entity
export const getLoanEntity = (loanId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanService.GetEntityDetails(loanId);
    response.loaded = true;
    dispatch(setLoanEntity(response));
  } catch (e) {
    dispatch(setLoanEntity({ loaded: true }));
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

/// Disburse
export const disburseLoan = (loan) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const disbTxDto = {
      identificationNumber: loan.identificationNumber,
      loanId: loan.loanId,
      transactionAmount: loan.transactionAmount,
      transactionDate: loan.transactionDate,
      paymentStartDate: loan.paymentStartDate,
      InsuranceExpirationDate: loan.insuranceExpirationDate,
      transactionType: 'DISB',
      paymentMethod: loan.paymentMethod,
      counterpartyBankAccountId: loan.counterpartyBankAccountId,
    };
    const response = await loanTransactionService.DisburseLoan(disbTxDto);
    if (loan.downPayment && loan.downPaymentAmount > 0) {
      const bodyTransaction = {
        identificationNumber: loan.identificationNumber,
        loanId: loan.loanId,
        transactionAmount: loan.downPaymentAmount,
        transactionDate: loan.transactionDate,
        transactionType: 'PYMT',
        paymentMethod: loan.paymentMethod,
      };
      await loanTransactionService.SetTransactionsInfo(bodyTransaction);
    }
    dispatch(getLoanEntity(loan.loanId));
    dispatch(setMessageSnackbar(response, 'success'));
    dispatch(setDisburseDialogOpen(false));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

/// change loan status
export const changeLoanStatus = (loan) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const changeStatusDto = {
      Comment: loan.comment,
      dateClosed: loan.dateClosed,
      defaultDate: loan.defaultDate,
      IdentificationNumber: loan.identificationNumber,
      LoanId: loan.loanId,
      StatusDestination: loan.loanStatusDestination,
      StatusOrigin: loan.loanStatusOrigin,
    };
    const response = await loanService.SetLoanStatus(changeStatusDto);
    if (response.result.processed) {
      dispatch(getLoanEntity(loan.loanId));
      dispatch(setMessageSnackbar(response.result.message, 'success'));
    } else {
      dispatch(setMessageSnackbar(response.result.message, 'error'));
    }
    return true;
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    return false;
  } finally {
    dispatch(finishLoading());
  }
};

/// MarkLoanAvailableForInvestors

export const markLoanAvailableForInvestors = (loanId, status) => async (dispatch) => {
    try {
      const response = await loanService.MarkLoanAvailableForInvestors(
        loanId,
        status,
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getLoanEntity(loanId));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(finishLoading());
    }
  };
export const getLoanTransactionsAsync = (loanId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanTransactionService.GetLoanTransactions(loanId);
    dispatch(setLoanTransactions(response?.data));
  } catch (e) {
    dispatch(setLoanTransactions([]));
    dispatch(setMessageSnackbar(e, 'warning'));
  } finally {
    dispatch(finishLoading());
  }
};
export const onDeleteConfirmedLoanTransactionAsync = (loanTransactionId, reverseDescription, loanId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await loanTransactionService.ReverseLoanTransaction(
        loanTransactionId,
        reverseDescription,
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getLoanEntity(loanId));
      dispatch(getLoanTransactionsAsync(loanId));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
export const onApproveTransactionClick = (loanTransactionId, loanId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await loanTransactionService.SetTransactionStatus(
        loanTransactionId,
        'A',
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getLoanTransactionsAsync(loanId));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
export const onRejectTransactionClick = (loanTransactionId, loanId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await loanTransactionService.SetTransactionStatus(
        loanTransactionId,
        'R',
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getLoanTransactionsAsync(loanId));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
