export default {
  bankAccounts: '/api/Banks',
  cities: '/api/Cities',
  countries: '/api/Countries',
  creditParams: '/api/LoanProductCreditParams',
  founders: '/api/Funder',
  funders: '/api/Funder',
  loanRequest: '/api/loanRequest',
  loans: '/api/loans',
  originators: '/api/Originators',
  regions: '/api/Regions',
  roles: '/api/Rol',
  transactions: '/api/loanTransactions',
  users: '/api/Users',
  company: '/api/company',
  entity: '/api/Entity',
  transUnion: '/api/TransUnion',
  renting: '/api/Renting',
  reports: '/api/Reports',
  experian: '/api/Datacredito',
  accountingFiles: '/api/AccountingFiles',
  clients: '/api/clients',
  sourceIncome: '/api/sourceIncome',
  master: '/api/master',
  relatedParties: '/api/form-acelera/related-parties',
  companyTypes: '/api/company-types',
  tasks: '/api/tasks',
  loanProduct: '/api/loan-product',
  customers: '/api/Customers',
  loanRequestProduct: '/api/loanRequestproduct',
  factorRules: '/api/FactorRules',
  loanDebtCollectionManagement: '/api/loan-debt-collection-management',
  account: '/api/Account',
  originatorPaymentMethods: '/api/OriginatorPaymentMethods/byOriginatorId',
  organizationSetting: '/api/OrganizationSettings/GetOrganizationSetting',
  availableIntegration: '/api/OriginatorIntegration/GetAvailableIntegrations',
  availableParameters: '/api/OriginatorIntegration/GetParametersAvailable',
  saveOrganizationSetting: '/api/OrganizationSettings/SaveOrganizationSetting',
  getPlaidParameters: '/api/OriginatorIntegration/GetPlaidParameters',
  savePlaidParameters: '/api/OriginatorIntegration/SavePlaidParameters',
  getACHQParameters: '/api/OriginatorIntegration/GetACHQParameters',
  saveACHQParameters: '/api/OriginatorIntegration/SaveACHQParameters',
  getStripeParameters: '/api/OriginatorIntegration/GetStripeParameters',
  saveStripeParameters: '/api/OriginatorIntegration/SaveStripeParameters',
  customerOriginators: '/api/CustomerOriginators',
  docusign: '/api/docusign',
  campaigns: '/api/Campaign',
  notifications: '/api/Notification',
  notes: '/api/LoanNotes',
  statementAttachment: '/api/LoanStatementAttachment',
  insurancesNotes: '/api/InsurancesNotes',
  riskManagementFilter: '/api/RiskManagementFilter',
};
