import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class LoanTransactionService {
  GetLoanTransactions = (loanId) => axiosService.get(`/api/LoanTransactions/transactionsByLoanId/${loanId}`);

  DisburseLoan = async (disbTxDto) => axiosService.post(
      `${routes.transactions}/CreateLoanDisbursement`,
      disbTxDto,
    );

  ReverseLoanTransaction = async (loanTransactionId, comment) => axiosService.delete(
      `${routes.transactions}/${loanTransactionId}/${comment}`,
    );

  SetTransactionStatus = async (loanTransactionId, status) => {
    const putData = JSON.stringify(status);
    return axiosService.put(
      `${routes.transactions}/set_status/${loanTransactionId}`,
      putData,
    );
  };

  GetLoanTransactionByStatus = (date) => axiosService.get(`/api/LoanTransactions/transactionsByStatus/${date}`);

  CreateACHQPayment = async (data) => axiosService.post('/api/ACHQPayment', JSON.stringify(data));

  SetTransactionsInfo = async (data) => axiosService.post('/api/Transactions', data);

  GetTransactionsGroupedByPayment = async (loanId) => axiosService.get(
      `${routes.transactions}/TransactionsGroupedByPaymentType/${loanId}`,
    );

  SendPaymentReportAsync = async (sendPaymentReportDTO) => axiosService.post(
      `${routes.transactions}/SendPaymentReport`,
      sendPaymentReportDTO,
    );

  CreateACHQPaymentWithLInk = async (data) => axiosService.post('/api/ACHQPayment/PaymentLinkACHQ', JSON.stringify(data));

  SetAutoPaymentInfoWithLInk = async (data) => axiosService.post(
      '/api/ACHQPayment/SetAutoPaymentInfoWithLInk',
      JSON.stringify(data),
    );

  GenerateInterestPaymentPdf = async (loanId) => axiosService.getBlobType(
      `${routes.transactions}/interest-payment-pdf/${loanId}`,
    );

  SendInterestPaymentDocumentAsync = async (sendInterestPaymentDTO) => axiosService.post(
      `${routes.transactions}/SendInterestPaymentDocument`,
      sendInterestPaymentDTO,
    );
}

const loanTransactionService = new LoanTransactionService();

export default loanTransactionService;
