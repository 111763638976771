import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { logout } from 'src/store/actions/accountActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      window.location.href = '/';
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        ref={ref}
        alignItems="center"
        component={ButtonBase}
        display="flex"
        onClick={handleOpen}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={account?.user?.avatar}
        />
        <Hidden mdDown>
          <Typography style={{ color: '#000' }} variant="h6">
            {account?.user?.firstName && account?.user?.lastName ? `${account?.user?.firstName} ${account?.user?.lastName}` : `${account?.user?.email}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={isOpen}
        PaperProps={{ className: classes.popover }}
      >
        <MenuItem component={RouterLink} to="/app/account">
          {t('Account')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
