import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';
import { logoutExpiredSession } from 'src/store/actions/accountActions';
import { configureStore } from 'src/store';
import i18n from 'src/i18n/i18n';

class AxiosService {
  // eslint-disable-next-line class-methods-use-this
  headers() {
    const appClientId = localStorage.getItem('appClientId');
    const hostName = window.location.hostname;
    const header = {
      Authorization: axios.defaults.headers.common.Authorization,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': i18n.language,
    };

    if (appClientId) {
      return {
        ...header,
        appClientId,
      };
    }
      return {
        ...header,
        urlTenant: hostName,
      };
  }

  get(route, responseType) {
    return new Promise((resolve, reject) => {
      const responseTypeValue = responseType !== undefined ? responseType : 'application/json';
      axios
        .get(`${API_BASE_URL}${route}`, {
          headers: this.headers(),
          responseType: responseTypeValue,
        })
        .then(this.successCallback(resolve), this.rejectCallback(reject));
    });
  }

  getBlobType(route) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${route}`, {
          headers: this.headers(),
          responseType: 'blob',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(async (error) => {
          if (error.response && error.response.status === 400) {
            // Convert blob error response to JSON
            const blob = error.response.data;
            try {
              const text = await blob.text();
              const errorJson = JSON.parse(text);
              reject(errorJson);
            } catch (e) {
              const errMessage = {
                message: `Failed to parse error response:${e}`,
              };
              reject(errMessage);
            }
          } else {
            console.error('Unexpected error:', error);
            const errMessage = {
              message: 'Unexpected error',
            };
            reject(errMessage);
          }
        });
    });
  }

  postBlobType(route, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${route}`, data, {
          headers: this.headers(),
          responseType: 'blob',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(async (error) => {
          if (error.response && error.response.status === 400) {
            // Convert blob error response to JSON
            const blob = error.response.data;
            try {
              const text = await blob.text();
              const errorJson = JSON.parse(text);
              reject(errorJson);
            } catch (e) {
              const errMessage = {
                message: `Failed to parse error response:${e}`,
              };
              reject(errMessage);
            }
          } else {
            console.error('Unexpected error:', error);
            const errMessage = {
              message: 'Unexpected error',
            };
            reject(errMessage);
          }
        });
    });
  }

  post(route, data, responseType) {
    return new Promise((resolve, reject) => {
      const responseTypeValue = responseType !== undefined ? responseType : 'application/json';
      axios
        .post(`${API_BASE_URL}${route}`, data, {
          headers: this.headers(),
          responseType: responseTypeValue,
        })
        .then(this.successCallback(resolve), this.rejectCallback(reject));
    });
  }

  put(route, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${API_BASE_URL}${route}`, data, { headers: this.headers() })
        .then(this.successCallback(resolve), this.rejectCallback(reject));
    });
  }

  delete(route) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${API_BASE_URL}${route}`, { headers: this.headers() })
        .then(this.successCallback(resolve), this.rejectCallback(reject));
    });
  }

  successCallback = (resolve) => (response) => {
    if (response.data) resolve(response.data);
    resolve(response);
  };

  rejectCallback = (reject) => (err) => {
    const error = err;
    if (err.response?.status === 400) error.message = 'Error connecting to server';
    if (err.response?.status === 401) {
      const store = configureStore;
      store.dispatch(logoutExpiredSession());
      error.message = 'unauthorized';
    }
    if (err.response?.status === 403) error.message = 'forbidden';
    if (err.response?.status === 500) error.message = 'internalServerError';
    if (typeof err.response?.data === 'string' && err.response.data !== '') error.message = err.response.data;
    if (
      typeof err.response?.data?.message === 'string'
      && err.response.data.message !== ''
    ) error.message = err.response.data.message;
    reject(error);
  };
}

const axiosService = new AxiosService();
export default axiosService;
