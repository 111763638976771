import React from 'react';
import { OnErrorLogo } from '../utils/urlPath';

function LogoThree(props) {
  return (
    <img
      align="center"
      alt="Logo"
      height="40px"
      onError={(e) => OnErrorLogo(e)}
      src="/static/logo-three.png"
      {...props}
    />
  );
}

export default LogoThree;
