/* eslint-disable import/prefer-default-export */
export const THEMES = {

  /*
   * LIGHT: 'LIGHT',
   * AZUL: 'AZUL',
   */
  ACELERA: 'ACELERA',
  ONE_DARK: 'ONE_DARK',

  /*
   * ONE_DARK: 'ONE_DARK',
   * UNICORN: 'UNICORN',
   * MICREDI: 'MICREDI'
   */
};
export const TABS_CAMPAIGN = {
  filters: 'FILTERS',
  rules: 'RULES',
};
export const LANGUAGE = [
  { description: 'Español', value: 'es' }, { description: 'English', value: 'en' },
];

/* Gender types */
export const GenderTypes = [
  { type: 'Female', value: 'F' },
  { type: 'Male', value: 'M' },
  { type: 'Undefined', value: 'U' },
  { type: 'Other', value: 'O' },
];

/* Payment types */
export const PymtMethod = [
  { type: 'PSE', value: 'PSE' },
  { type: 'Red ATH', value: 'ATH' },
  { type: 'Consignación', value: 'Consignación' },
  { type: 'Refinanciación', value: 'Refinanciación' },
  { type: 'Otro', value: 'Otro' },
];

export const loanStatusList = [
  { value: '', name: 'all' },
  { value: 'P', name: 'Pending' },
  { value: 'A', name: 'active' },
  { value: 'M', name: 'Overdue' },
  { value: 'X', name: 'Charged off' },
  { value: 'C', name: 'Paid off' },
  { value: 'D', name: 'Deleted' },
  { value: 'Z', name: 'Closed' },
  { value: 'W', name: 'waived' },
];

export const actionableLoanStates = ['P',
'W',
'X',
'C',
'D'];

export const payableLoanStatus = ['A', 'M'];

export const multipleDisburseLoanStatus = ['A',
'P',
'M'];

export const actionableMarkLoanForSale = ['A', 'M'];

export const loanStatusPendOrWaived = ['P', 'W'];

export const loanStatusChanges = {
  P: ['W', 'D'],
  W: ['P'],
  A: [],
  M: ['X'],
  X: ['C', 'M'],
  C: ['X', 'Z'],
  D: ['P'],
  Z: [],
};

export const requestStatusList = [
  { value: '', name: 'None' },
  { value: 'I', name: 'Initiated' },
  { value: 'P', name: 'Pending' },
  { value: 'A', name: 'Approved' },
  { value: 'N', name: 'Denied' },
  { value: 'S', name: 'In study' },
  { value: 'D', name: 'deleted' },
  { value: 'R', name: 'returned' },
  { value: 'W', name: 'waived' },
];

/* Occupation types */
export const OccupationTypes = [
  { value: 'E', name: 'employee' },
  { value: 'I', name: 'independent' },
  { value: 'R', name: 'retired' },
  { value: 'U', name: 'unemployed' },
];
export const OccupationTypesCampaigns = [
  { value: 'Empleado', name: 'employee' },
  { value: 'Independiente', name: 'independent' },
  { value: 'Retirado', name: 'retired' },
  { value: 'Desempleado', name: 'unemployed' },
];

/* Id types to person */
export const IdTypes = [
  { type: 'Cédula de Ciudadanía', value: 'CC', enrollment: 'C' },
  { type: 'Cédula de Extranjería', value: 'CE', enrollment: 'E' },
  { type: 'Permiso Especial de Permanencia', value: 'PEP' },
];

/* Id types to company */
export const IdTypesCompany = [
  { value: 'SSN', name: 'SSN', country: 'US' },
  { value: 'ITIN', name: 'ITIN', country: 'US' },
  { value: 'DRLI', name: 'driverLicense', country: 'US' },
  { value: 'TIN', name: 'TIN', country: 'US' },
  { value: 'EIN', name: 'EIN', country: 'US' },
  { value: 'NIT', name: 'NIT', country: 'CO' },
];

export const Familiar = [
  { type: 'Padre/Madre', value: 'fatherMother' },
  { type: 'Hermano(a)', value: 'brotherSister' },
  { type: 'Hijo(a)', value: 'sonDaughter' },
  { type: 'Primo(a)', value: 'cousin' },
  { type: 'Tío(a)', value: 'uncleAunt' },
  { type: 'Esposo(a)', value: 'husbandWife' },
  { type: 'Otro', value: 'other' },
];

export const Laboral = [
  { type: 'Jefe Directo', value: 'directChief' },
  { type: 'Recursos Humanos', value: 'humanResources' },
  { type: 'Socio', value: 'partner' },
  { type: 'Cliente', value: 'client' },
  { type: 'Proveedor', value: 'supplier' },
  { type: 'Otro', value: 'other' },
];

/* Phone types */
export const PhoneTypes = [
  { type: 'Celular', value: 'CP' },
  { type: 'Teléfono fíjo', value: 'HL' },
  { type: 'Teléfono laboral', value: 'WL' },
];

/* Address types */
export const AddressTypes = [
  { type: 'Dirección residencial', value: 'HA' }, { type: 'Dirección laboral', value: 'WA' },
];

/* Bank names - Colombia */
export const bankNameList = [
  { code: '40', data: 'Banco Agrario' },
  { code: '52', data: 'Banco AV Villas' },
  { code: '32', data: 'Banco Caja Social' },
  { code: '19', data: 'Banco Colpatria (Scotiabank)' },
  { code: '06', data: 'Banco Corpbanca S.A.' },
  { code: '51', data: 'Banco Davivienda' },
  { code: '01', data: 'Banco de Bogotá' },
  { code: '23', data: 'Banco de Occidente' },
  { code: '62', data: 'Banco Falabella' },
  { code: '12', data: 'Banco GNB Sudameris' },
  { code: '14', data: 'Banco Itaú' },
  { code: '60', data: 'Banco Pichincha S.A.' },
  { code: '02', data: 'Banco Popular' },
  { code: '58', data: 'Banco Procredit' },
  { code: '07', data: 'Bancolombia' },
  { code: '61', data: 'Bancoomeva S.A.' },
  { code: '13', data: 'BBVA Colombia S.A.' },
  { code: '63', data: 'Banco Finandina' },
  // {code:"09", data:"Citibank"},
];

export const bankNameListUSA = [
  { code: '102', data: 'American Express' },
  { code: '103', data: 'Apple Bank for Savings' },
  { code: '105', data: 'Bank of America' },
  { code: '107', data: 'BankBoston' },
  { code: '108', data: 'BB&T' },
  { code: '109', data: 'BBVA' },
  { code: '112', data: 'Chase Manhattan Bank' },
  { code: '114', data: 'Citibank' },
  { code: '115', data: 'Citigroup' },
  { code: '117', data: 'Dime Savings Bank of Williamsburgh' },
  { code: '120', data: 'First Financial Bank (Ohio) ' },
  { code: '121', data: 'FNB Corporation' },
  { code: '122', data: 'Goldman Sachs' },
  { code: '123', data: 'Greenwich Savings Bank' },
  { code: '124', data: 'JP Morgan Chase' },
  { code: '127', data: 'Merrill Lynch' },
  { code: '128', data: 'Moore Capital Management' },
  { code: '129', data: 'Morgan Stanley' },
  { code: '130', data: 'New York Savings Bank' },
  { code: '131', data: 'Riggs Bank' },
  { code: '132', data: 'Santander Bank' },
  { code: '133', data: 'State Street Corporation' },
  { code: '134', data: 'SunTrust Banks' },
  { code: '135', data: 'Wells Fargo' },
];

/* Account types */
export const AccountTypes = [
  { type: 'Saving', value: 'AHO' }, { type: 'Current', value: 'CTE' },
];

export const MemberTypes = [
  { type: 'Persona Natural', value: '"M"' }, { type: 'Persona Jurídica', value: '"C"' },
];

export const sortOptions = [
  {
    value: 'desc',
    label: 'Descending',
  },
  {
    value: 'asc',
    label: 'Ascending',
  },
];

export const CustomerType = {
  person: 'person',
  company: 'company',
};

export const MemberStatus = [
  { value: 'P', name: CustomerType.person }, { value: 'E', name: CustomerType.company },
];

export const AutoPaymentSelect = [
  { value: 'all', name: 'all' },
  { value: 'active', name: 'active' },
  { value: 'inactive', name: 'inactive' },
];

export const TypeStatus = [
  { value: 'IdentificationNumber', name: 'Número de identificación' },
  { value: 'Id', name: 'EntityId' },
  { value: 'EmailAddress', name: 'Email' },
  { value: 'PhoneNumber', name: 'Celular' },
  { value: 'FirstName', name: 'Nombres' },
  { value: 'LastName', name: 'Apellidos' },
];

export const PreQualificationStatus = [
  { value: 'N', name: 'Negado' },
  { value: 'A', name: 'Aprobado' },
  { value: 'S', name: 'Estudio' },
];

export const Status = [
  { value: 1, name: 'active' }, { value: 0, name: 'inactive' },
];

export const Alloriginator = [{ id: '0', name: 'All' }];

export function defineName(type, constant, description) {
  if (type === 'l') {
    switch (constant) {
      case 'A': {
        const loanStatus = 'Activo';
        return loanStatus;
      }
      case 'P': {
        const loanStatus = 'Pendiente';
        return loanStatus;
      }
      case 'M': {
        const loanStatus = 'Mora';
        return loanStatus;
      }
      case 'C': {
        const loanStatus = 'Pagado';
        return loanStatus;
      }
      case 'Z': {
        const loanStatus = 'Cerrado';
        return loanStatus;
      }
      case 'D': {
        const loanStatus = 'Borrado';
        return loanStatus;
      }
      default: {
        const loanStatus = 'Sin estado';
        return loanStatus;
      }
    }
  } else if (type === 't') {
    switch (constant) {
      case 'PYMT': {
        const transactionType = 'Pago';
        return transactionType;
      }
      case 'REV': {
        const transactionType = 'Reversión';
        return transactionType;
      }
      case 'DISB': {
        const transactionType = 'Desembolso';
        return transactionType;
      }
      case 'A': {
        const transactionStatus = 'Aprobado';
        return transactionStatus;
      }
      case 'P': {
        const transactionStatus = 'Pendiente';
        return transactionStatus;
      }
      case 'R': {
        const transactionStatus = 'Rechazado';
        return transactionStatus;
      }
      default: {
        const transactionType = null;
        return transactionType;
      }
    }
  } else if (type === 'r') {
    switch (constant) {
      case 'I': {
        const transactionType = 'Iniciado';
        return transactionType;
      }
      case 'P': {
        const transactionType = 'Pendiente';
        return transactionType;
      }
      case 'A': {
        const transactionType = 'Aprobado';
        return transactionType;
      }
      case 'N': {
        const transactionStatus = 'Negado';
        return transactionStatus;
      }
      case 'S': {
        const transactionStatus = 'En estudio';
        return transactionStatus;
      }
      case 'R': {
        const transactionStatus = 'Rechazado';
        return transactionStatus;
      }
      case 'D': {
        const transactionStatus = 'Borrado';
        return transactionStatus;
      }
      case 'E': {
        const transactionStatus = 'En desembolso';
        return transactionStatus;
      }
      case 'LoanRequest': {
        const transactionStatus = 'Solicitudes';
        return transactionStatus;
      }
      case 'Loan': {
        const transactionStatus = 'Préstamos';
        return transactionStatus;
      }
      default: {
        const transactionType = null;
        return transactionType;
      }
    }
  } else if (type === 'c') {
    switch (constant) {
      case 'PR': {
        const ChargesName = 'Capital';
        return ChargesName;
      }
      case 'CH': {
        let ChargesName = '';
        if (description === 'Administrative') {
          ChargesName = 'Administrativos';
        } else if (description === 'TechPlatform') {
          ChargesName = 'Plataforma';
        } else if (description === 'Insurance') {
          ChargesName = 'Seguro';
        } else if (description === 'Renew') {
          ChargesName = 'Renovación';
        } else if (description === 'Factor') {
          ChargesName = 'Cobranza';
        } else {
          ChargesName = 'Otros';
        }
        return ChargesName;
      }
      case 'NI': {
        const ChargesName = 'Interés';
        return ChargesName;
      }
      case 'PI': {
        const ChargesName = 'Interés Mora';
        return ChargesName;
      }
      default: {
        const ChargesName = 'Otros';
        return ChargesName;
      }
    }
  }
  return '';
}
export const ConditionOperator = '"%&&"';
export const ConditionOperatorAND = '%26';
export const ConditionOperatorOR = '%7C';

export const installmentTypes = [
  { id: '', name: '' },
  { id: 'EI', name: 'EquatedInstallments' },
  { id: 'NEI', name: 'NonEquatedInstallments' },
];

export const frequencies = [
  { id: '', name: '' },
  { id: 'B', name: 'Bullet' },
  { id: 'W', name: 'Weekly' },
  { id: 'F', name: 'Fortnightly' },
  { id: 'S', name: 'Semi-monthly' },
  { id: 'M', name: 'Monthly' },
  { value: 'Y', text: 'yearly' },
];

export const gracePeriodApplyForOptions = [
  { id: 0, name: '' },
  { id: 1, name: 'Principal' },
  { id: 2, name: 'Principal and interests' },
];

export function getDaysFrequency(frequency) {
  switch (frequency) {
    case 'M':
      return 30;
    case 'S':
      return 15;
    case 'F':
      return 14;
    case 'W':
      return 7;
    case 'B':
      return 1;
    default:
      return 0;
  }
}

export const paymentMethods = [
  { name: 'Manual', value: 'manual', enable: true },
  { name: 'Plaid + Stripe', value: 'stripe', enable: true },
  { name: 'ACHQ', value: 'achq', enable: true },
];

// Loan Request
export const RequestFrecuencyStatusTypes = [
  { value: 'B', text: 'atExpiration' },
  { value: 'W', text: 'weekly' },
  { value: 'F', text: 'fourteen' },
  { value: 'S', text: 'biweekly' },
  { value: 'M', text: 'monthly' },
  { value: 'Y', text: 'yearly' },
];

export function frecuencyType(type) {
  const frecuency = RequestFrecuencyStatusTypes.find((f) => f.value === type)
    ?? RequestFrecuencyStatusTypes[0];
  return frecuency.text;
}

export const LoanRequestStatusTypes = [
  { value: 'I', name: 'Initiated' },
  { value: 'P', name: 'Pending' },
  { value: 'A', name: 'Approved' },
  { value: 'N', name: 'Denied' },
  { value: 'S', name: 'underStudy' },
  { value: 'D', name: 'Deleted' },
  { value: 'R', name: 'returned' },
  { value: 'W', name: 'waived' },
];

export const LoanRequestType = [
  { value: 1, name: 'principal' }, { value: 2, name: 'coDebtor' },
];

export const EmploymentSituationOptions = [
  { value: 'Empleado', text: 'employee' },
  { value: 'Pensionado', text: 'pensionary' },
  { value: 'Ama de casa', text: 'housewife' },
  { value: 'Estudiante', text: 'student' },
  { value: 'Ganadero', text: 'livestock' },
  { value: 'Comerciante', text: 'merchant' },
  { value: 'Agricultor', text: 'farmer' },
  { value: 'Rentista de capital', text: 'capitalRentier' },
  { value: 'Independiente', text: 'selfEmployed' },
  { value: 'Desempleado sin ingresos', text: 'unemployedWithoutIncome' },
  { value: 'Desempleado con ingresos', text: 'unemployedWithIncome' },
  { value: 'Profesional independiente', text: 'independentProfessional' },
  { value: 'Socio', text: 'partner' },
  { value: 'Empleado – socio', text: 'employeePartner' },
];

export const ContractTypeOptions = [
  { value: 'No aplica', text: 'notApplicable' },
  { value: 'No soy empleado', text: 'imNotEmployed' },
  { value: 'Prestación de servicios', text: 'provisionOfServices' },
  { value: 'Término indefinido', text: 'indefiniteTerm' },
  { value: 'Término fijo', text: 'fixedTerm' },
  { value: 'Carrera administrativa', text: 'administrativeCareer' },
  { value: 'Contrato de corretaje', text: 'brokerageContract' },
  { value: 'Contrato educativo', text: 'educationalContract' },
  { value: 'Contrato plazo presuntivo', text: 'presumptiveTermContract' },
  { value: 'Libre nombramiento o remoción', text: 'freeAppointmentOrRemoval' },
  { value: 'Nombramiento en propiedad', text: 'proprietaryAppointment' },
  { value: 'Nombramiento por resolución', text: 'appointmentByResolution' },
  { value: 'Nombramiento provisional', text: 'provisionalAppointment' },
  { value: 'Nombramiento transitorio', text: 'temporaryAppointment' },
  { value: 'Obra Labor', text: 'workLabor' },
  { value: 'Otro', text: 'other' },
];

export const MonthsInJobOptions = [
  { value: '0', text: 'lessOfOneYear' },
  { value: '3', text: 'betweenOneAndThreeYears' },
  { value: '4', text: 'moreOfThreeYears' },
];

export const FamilyCompensationFundOptions = [
  { value: 'A', text: 'categoryA' },
  { value: 'B', text: 'categoryB' },
  { value: 'C', text: 'categoryC' },
  { value: 'D', text: 'categoryD' },
];

export const StudyStatusOptions = ['inProgress', 'finished'];

/* Account types */
export const DefaultCountry = 'CO';

export const YesNoOptions = [{ value: 'yes' }, { value: 'no' }];

export const MaritalStatusOptions = [
  { value: 'S', text: 'single' },
  { value: 'M', text: 'married' },
  { value: 'W', text: 'widowed' },
  { value: 'D', text: 'divorced' },
  { value: 'U', text: 'unmarried' },
];

export const BusinessTypes = [
  { value: 'P', name: 'propietorship' },
  { value: 'CC', name: 'cCorporation' },
  { value: 'SP', name: 'solePropietorship' },
  { value: 'PS', name: 'partnership' },
  { value: 'LLC', name: 'llc' },
];

export const PctCalcBaseList = [
  { value: 'loanAmount', key: 'LA' },
  { value: 'pendingAmount', key: 'PA' },
  { value: 'repaymentScheduleAmount', key: 'RA' },
  { value: 'disbursedAmount', key: 'DA' },
];

export const TypesOfHousing = [
  { value: 'O', text: 'owned' },
  { value: 'F', text: 'familyOwned' },
  { value: 'R', text: 'rented' },
];
export const LoanProductValidationApplyFor = [
  { value: 'loan', name: 'loans' }, { value: 'request', name: 'application' },
];
export const RequestTypeConstant = {
  Principal: 1,
  Codebtor: 2,
};
export const PolicyTypeConstant = [
  { key: 1, value: 'LI', text: 'lifeInsurance' },
  { key: 2, value: 'AI', text: 'autoInsurance' },
  { key: 3, value: 'HI', text: 'homeInsurance' },
  { key: 4, value: 'SI', text: 'healthInsurance' },
  { key: 5, value: 'PI', text: 'personalAccidentInsurance' },
  { key: 6, value: 'TI', text: 'travelInsurance' },
  { key: 7, value: 'OB', text: 'otherBranches' },
];
export const InsuranceCompanyConstant = [
  { name: 'Allianz seguros', value: 'Allianz' },
  { name: 'Axa colpatria seguros', value: 'Axa Colpatria' },
  { name: 'BBVA', value: 'BBVA' },
  { name: 'Berkley', value: 'Berkley' },
  { name: 'CHUBB', value: 'CHUBB' },
  { name: 'Colmena seguros', value: 'Colmena seguros' },
  { name: 'Confianza', value: 'Confianza' },
  { name: 'HDI', value: 'HDI' },
  { name: 'La Equidad', value: 'La Equidad' },
  { name: 'La Previsora', value: 'La Previsora' },
  { name: 'Liberty', value: 'Liberty' },
  { name: 'Mapfre', value: 'Mapfre' },
  { name: 'MetLife', value: 'MetLife' },
  { name: 'Nacional de seguros S.A.', value: 'Nacional de seguros S.A.' },
  { name: 'SBS', value: 'SBS' },
  { name: 'Bolívar S.A.', value: 'Bolívar S.A.' },
  { name: 'Estado S.A.', value: 'Estado S.A.' },
  { name: 'Seguros mundial', value: 'Seguros Mundial' },
  { name: 'Aseguradora solidaria de colombia', value: 'Solidaria' },
  { name: 'Sura seguros', value: 'SURA' },
  { name: 'Zurich', value: 'Zurich' },
];
export const TypeProductLoanRequestConstant = [
  { key: 1, text: 'Insurance' }, { key: 2, text: 'Vehicle' },
];
export const ProductTypeInfoConstant = {
  Insurance: 'Insurance',
  Vehicle: 'Vehicle',
};

export const LoanProductChargeTypeFrecuencyConstants = [
  { key: 1, value: 'Disbursement', text: 'disbursement' }, { key: 2, value: 'EveryInstallment', text: 'everyInstallment' },
];
export const EntityTypeConstants = {
  Person: 'P',
  Company: 'C',
};

export const DebtCollectionActivityResultConstants = [
  { value: 1, text: 'dontAnswer' },
  { value: 2, text: 'incorrectNumber' },
  { value: 3, text: 'paymentAgreement' },
  { value: 4, text: 'alreadyPaid' },
  { value: 5, text: 'reluctantToPay' },
  { value: 6, text: 'notAbleToPay' },
];

export const frequencyList = [
  { value: 'O', name: 'once' },
  { value: 'D', name: 'daily' },
  { value: 'W', name: 'weekly' },
  { value: 'M', name: 'monthly' },
  { value: 'Q', name: 'quarterly' },
  { value: 'B', name: 'biannual' },
  { value: 'Y', name: 'yearly' },
];

export const tasksStatusList = [
  { value: 'A', name: 'active' },
  { value: 'O', name: 'overdue' },
  { value: 'C', name: 'completed' },
  { value: 'D', name: 'canceled' },
];

export const timeZones = [
  { value: 'SAPDT', name: 'SA Pacific Standard Time' }, { value: 'PST', name: 'Pacific Standard Time' },
];

export const autopaymentMethods = [
  { value: 'STRIPE', name: 'Stripe', active: false },
  { value: 'PLAID', name: 'Plaid Transfer', active: false },
  { value: 'ACHQ+PLAID', name: 'ACHQ + Plaid', active: false },
  { value: 'ACHQ', name: 'ACHQ', active: false },
];

export const userStatusList = [
  { value: '', name: '' },
  { value: 'disabled', name: 'disabled' },
  { value: 'enabled', name: 'enabled' },
];

export const maskIdentification = [
  { value: 'Ninguno', name: '999999999' },
  { value: 'Cédula de ciudadanía', name: '999999999' },
  { value: 'Pasaporte', name: '999999999' },
  { value: 'NIT', name: '999999999' },
  { value: 'Cédula de extranjería', name: '999999999' },
  { value: 'SSN - Social Security Number', name: '999-99-9999' },
  {
    value: 'ITIN - Individual Taxpayer Identification Number',
    name: '999-99-9999',
  },
  { value: "Driver's license", name: '999999999' },
  { value: 'TIN - Taxpayer Identification Number', name: '999-99-9999' },
  { value: 'EIN - Employer Identification Number', name: '99-99999999' },
  { value: 'Passport', name: '999999999' },
];

export const legalRepresentative = [
  { text: 'yes', value: true }, { text: 'no', value: false },
];
export const requestStatus = {
  A: { label: 'Approved', color: 'success' },
  P: { label: 'Pending', color: 'warning' },
  M: { label: 'Overdue', color: 'error' },
  C: { label: 'Paid', color: 'success' },
  Z: { label: 'Closed', color: 'success' },
  D: { label: 'Erased', color: 'info' },
  S: { label: 'inStudy', color: 'primary' },
  R: { label: 'returned', color: 'warning' },
  I: { label: 'Initiated', color: 'primary' },
  N: { label: 'Denied', color: 'error' },
};

export const paymentTypeList = [
  {
    value: 'capital',
    key: 'PR',
  },
  {
    value: 'charges',
    key: 'CH',
  },
  {
    value: 'currentInterest',
    key: 'NI',
  },
  {
    value: 'penalInterest',
    key: 'PI',
  },
];

export const applicationStatusRequestSignature = {
  Initiated: 'I',
  Pending: 'P',
  Study: 'S',
};

export const campaignTypeList = [
  { value: 'CPT', name: 'corporate' },
  { value: 'LYT', name: 'loyalty' },
  { value: 'PTN', name: 'positioning' },
  { value: 'PMT', name: 'promotional' },
  { value: 'RKT', name: 'remarketing' },
];

export const executionFrequencyList = [
  { value: 'DLY', name: 'daily' },
  { value: 'WKY', name: 'weekly' },
  { value: 'MLY', name: 'monthly' },
  { value: 'YLY', name: 'yearly' },
];

export const enrollmentStatus = [
  { value: 'CLM', name: 'culminated' }, { value: 'NLM', name: 'unfinished' },
];

export const channelList = [
  { name: 'Admin' },
  { name: 'Web' },
  { name: 'iKiwi' },
];

export const reportTypeListConst = {
  cutOfDate: 'cutOfDate',
  rangeDates: 'rangeDates',
  noDate: 'noDate',
};

export const transactionStatusList = {
  P: 'Pending',
  A: 'Approved',
  R: 'Rejected',
};

export const TRANSACTION_STATUS = Object.freeze({
  DISB: 'DISB', // DISBURSEMENT
  PYMT: 'PYMT', // PAYMENT
  REV: 'REV', // REVERSAL
});

export const PAYMENT_TYPES = Object.freeze({
  CAPITAL: 'PR',
  CURRENT_INTEREST: 'NI',
  CHARGES: 'CH',
  PENAL_INTEREST: 'PI',
});

export const AVAILABLE_FOR_SALE_OR_SOLD = Object.freeze({
  YES: 'YES',
  NO: 'NO',
  SOLD: 'SOLD',
  INELIGIBLE: 'INELIGIBLE',
  invalidStatusMarkForSale: ['YES', 'SOLD'],
});

export const getInstallmentStatusColor = (status) => {
  let statusColor = 'secondary';
  switch (status) {
    case 'Active':
      statusColor = 'primary';
      break;
    case 'Paid':
      statusColor = 'success';
      break;
    case 'Deleted':
      statusColor = 'warning';
      break;
    case 'Overdue':
    case 'NotPaid':
      statusColor = 'error';
      break;
    default:
      break;
  }
  return statusColor;
};

export const includesOne = (arr, value) => arr.some((v) => value.includes(v));
export const includesAny = (arr, values) => values.some((v) => arr.includes(v));

export const defaultThemeTextEditor = {
  overrides: {
    MuiIconButton: {
      root: {
        padding: '5px',
      },
    },
    MUIRichTextEditor: {
      root: {
        backgroundColor: '#fff',
        border: '1px solid gray',
      },
      editor: {
        backgroundColor: '#fff',
        minHeight: '50px',
        maxWidth: '1200px',
        overflow: 'auto',
        padding: '0px 5px',
      },
      toolbar: {
        backgroundColor: '#ebebeb',
      },
      placeHolder: {
        padding: '10 10px',
        position: 'absolute',
        color: '#a9a9a9',
      },
      container: {
        margin: '0px 0px 0px 0px',
      },
    },
  },
};

export const ROLES_ACCESS = Object.freeze({
  ADMIN_ROLES_ACCESS: Object.freeze(['SuperUser', 'Admin']),
  LOAN_SERVICER: 'LoanServicer',
  CUSTOMER_SERVICE: 'CustomerService',
  ACCOUNTING_DEPT: 'AccountingDept',
});

export const STATUS_LOAN_TO_ALLOW_MODIFICATION = ['P',
'A',
'M'];

/* Repossession Type */
export const RepossessionTypes = [
  { value: null },
  { value: 'voluntary' },
  { value: 'involuntary' },
];

/* Conditions Of Collateral */
export const ConditionsOfCollateral = [
  { value: null },
  { value: 'good' },
  { value: 'poor' },
  { value: 'damaged' },
  { value: 'totalLost' },
];

/* Continue Payment or Make Whole */
export const ContinuePaymentOrMakeWhole = [
  { value: null },
  { value: 'continuePayment' },
  { value: 'makeWhole' },
];

export const DefaultAndRepossessionFilesType = {
  conditionOfCollateralFiles: 'conditionOfCollateralFiles',
  priceDigestValueFiles: 'priceDigestValueFiles',
  liquidationAmountFiles: 'liquidationAmountFiles',
};

export const BILL_OF_SALE_DOCUMENT = 'BILL_OF_SALE_DOCUMENT';
export const INVOICE_DOCUMENT = 'INVOICE_DOCUMENT';

export const PORTFOLIO_TYPE_STATE_GUARANTEE = 'stateGuarantee';
export const PORTFOLIO_TYPE_CALCAP = 'calCap';

export const PORTFOLIO_TYPE_ACCESS = Object.freeze([
  PORTFOLIO_TYPE_STATE_GUARANTEE, PORTFOLIO_TYPE_CALCAP,
]);

export const LOAN_CLOSURE_CATEGORY_FILES = Object.freeze([
  { value: 'UPLOADED_BY_USER', name: 'uploadedByUser' },
  { value: 'NOTICE_LOAN_DEFAULT', name: 'noticeLoanDefault' },
  { value: 'DEMAND_LETTER', name: 'demandLetter' },
]);

export const HaulingTypes = [
  {
    value: 'Out California',
  },
  {
    value: 'In California',
  },
  { value: 'Both' },
];
