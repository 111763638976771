export const loanDetailsEn = {
  'A transaction still exists on this loan':
    'A transaction still exists on this loan',
  accountId: 'Account id',
  transactionCompletedNotificationDidNotSend:
    'Transaction completed notification did not send',
  accountToken: 'Account Token',
  accountNumber: 'Account Number',
  activity: 'Activity',
  Activity: 'Activity',
  activityDate: 'Activity Date',
  agreementDate: 'Date Agreement',
  alreadyPaid: 'Already Paid',
  areYouSureYouWantMarkLoanForSale: 'Are you sure you want mark loan for sale?',
  autoPayment: 'Auto Payment',
  autoPaymentParameters: 'Auto Payment Parameters',
  availableForSale: 'Available for Sale',
  bankAccount: 'Bank Account',
  bankName: 'Bank Name',
  'Change rate': 'Change Rate',
  chargesAddedValueLoan: 'Charges added to the value of the loan',
  chargesDeductedDisbursement: 'Charges deducted at disbursement',
  changeLoanStatusDescription: 'Select Destination Status',
  changeStatus: 'Change Status',
  'Charge amount': 'Charge Amount',
  company: 'Company',
  Complete: 'Complete',
  currentStatus: 'Current State',
  customerDetails: 'Borrower Details',
  'Delete loan installment': 'Delete Loan Installment',
  destinationState: 'Destination State',
  disbAmountLabel: 'Disbursement Amount',
  disbAmtExceedsLoanAmt: 'The disbursement amount exceeds the loan amount.',
  generateDisbursementFile: 'Generate batch disbursement file',
  disburse: 'Disburse',
  disbursedAmount: 'Disbursed Amount',
  disburseLabelBtn: 'Disburse',
  disburseLoan: 'Disburse Loan',
  'Disbursement transaction made.': 'Disbursement Transaction Made.',
  disbursementDate: 'Disbursement Date',
  dontAnswer: 'Did not answer',
  download: 'Download',
  downloadDisbFileSubTtl:
    'Select account and date in order to generate the disbursement file',
  downloadDisbFileTtl: 'Downloand Disbursement File',
  loanEffectiveDate: 'Effective Date',
  loanEffectiveDateCapital: 'Effective Date',
  paymentDueDate: 'Due',
  enterCustomerNameIdentification: 'Enter borrower name or identification',
  enterProductNameId: 'Enter name or identification product',
  entity: 'Entity',
  EquatedInstallments: 'Equated Installments',
  evidences: 'Evidences',
  'Existe un préstamo activo para la solicitud.':
    'There is an active loan for the application.',
  fileDeleted: 'File deleted',
  filesAreRequired: 'Files are required',
  from: 'from',
  getLoanInfoFailed: 'Loan not found',
  'Grace period': 'Grace Period',
  'Grace period for': 'Grace Reriod For',
  'Grace periods': 'Grace Periods',
  'Grace periods apply for': 'Grace Periods Apply For',
  'Has grace period?': 'Has Grace Period?',
  inactive: 'Inactive',
  incorrectNumber: 'Incorrect Number',
  'Installment amount': 'Installment Amount',
  totalDueAmount: 'Total Due Amount',
  'Installment of': 'Installment of',
  'Installment type': 'Installment Type',
  'Interest amount': 'Interest Amount',
  interestRate: 'Interest Rate',
  'La fecha inicial de pago no puede ser menor a la fecha de la transacción. ':
    'The initial payment date cannot be less than the date of the transaction.',
  'La solicitud debe estar en estado Iniciada para poder ser borrada. ':
    'The application must be in the Started state in order to be deleted.',
  lastTranDisbError:
    'The disbursement date cannot be less than the last transaction date.',
  loan: 'Loan',
  'Loan details': 'Loan details',
  'Loan Rescheduling': 'Loan Rescheduling',
  loanCode: 'Loan Code',
  loanCodeCapital: 'Loan Code',
  loanAmount: 'Loan Amount',
  prLoanAmount: 'Loan Amount',
  loanAvailableForSale: 'Loan Available for Sale',
  loanChanged: 'Loan Status Changed',
  loanChangeNoAllowed: 'Loan Status Change no allowed',
  loanDetails: 'Loan Details',
  loanInstallment: 'Loan Installment',
  loanManagement: 'Loan Management',
  loanNoFound: 'Loan no found',
  loanRequest: 'Loan Application',
  loanRequestValidations: 'Loan Application Validations',
  loanlist: 'Loan Portfolio',
  loansId: 'ID',
  loanToFactorDetails: 'Loan to Factory Details',
  loanTransaction: 'Loan Transaction',
  loanTransactions: 'Loan Transactions',
  loanValidations: 'Loan Validations',
  loanWithNoRepaymentSchedule: 'Loan with no repayment schedule',
  markForSale: 'Mark for Sale',
  noAllowedTransactions: 'This transaction is not allowed for this loan status',
  noAllowedTransactionsMenu: 'No allowed transactions',
  noContentFile: 'No disbursement files found.',
  noInvestorForLoan: 'There are no investor to assign to the loan.',
  noMultipleDisbAllowed: "The product doesn't allow multiple disbursements.",
  NonEquatedInstallments: 'Non Equated Installments',
  notAbleToPay: 'Not able to pay',
  notValidated: 'Not validated',
  numberInstallmentsMustGreaterEqualOne:
    'Number of installments must be greater than or equal to 1',
  numberInstallmentsExceedMaximumDate:
    'The number of installments exceed the maximum date 2100-01-01',
  'Number of installments': 'Number of Installments',
  numberOfInstallments: 'Number of Installments',
  observations: 'Observations',
  'Overdue date': 'Overdue Date',
  Overview: 'Overview',
  pay: 'Pay',
  Pay: 'Pay',
  'Paid amount': 'Paid Amount',
  paidAmount: 'Paid Amount',
  totalPaidAmount: 'Total Paid Amount',
  'Paid interest amount': 'Paid Interest Amount',
  paidInterestAmount: 'Paid Interest Amount',
  'Paid penal amount': 'Paid Penalty Amount',
  paidPenalAmount: 'Paid Penalty Amount',
  'Paid principal amount': 'Paid Principal Amount',
  paidPrincipalAmount: 'Paid Principal Amount',
  paidChargeAmount: 'Paid Charge Amount',
  'Payment start date': 'Payment Start Date',
  paymentStartDate: 'Payment Start Date',
  paymentMethodDisburse: 'Disburse Method',
  paymentPlan: 'Payment Plan',
  paymentPlanRestructured: 'Payment Plan Restructured',
  historicalPaymentPlan: 'Historical Payment Plan',
  'Penal amount': 'Penalty Amount',
  penalRate: 'Penal Rate',
  pendingAmount: 'Outstanding Amount',
  outstandingPrincipal: 'Outstanding',
  pendingPrincipal: 'Pending Principal',
  outstandingBalance: 'Outstanding Balance',
  pendingLoanInstallmentsExist:
    'There are pending installments with date greater than the disbursement date.',
  'Principal amount': 'Principal Amount',
  productType: 'Product Type',
  pymtLessFromDateError:
    'Payment start date cannot be less than Rescheduling from date',
  pymtStartDateLabel: 'Payment Start Date',
  insurancePaymentDate: 'Insurance Payment Date',
  insuranceExpirationDate: 'Insurance Expiration Date',
  pymtStartDateNotLessThanDisbDate:
    'Payment start date cannot be less than disbursement date.',
  'Raised?': 'Generated?',
  'Re-schedule': 'Re-schedule',
  'Record deleted successfully': 'Record deleted successfully',
  registerPaymentReceived: 'Register Payment',
  reluctantToPay: 'Does not want to pay',
  Reschedule: 'Reschedule',
  'Reschedule from date': 'Reschedule From Date',
  rescheduleFromDate: 'Reschedule From Date',
  'Rescheduling parameters': 'Rescheduling Parameters',
  reschedulingParametersr: 'Rescheduling Parameters',
  'Rescheduling successful message':
    'Rescheduling of loan payment plan has been completed successfully.',
  reverseLblBtn: 'Reverse',
  reverseTransaction: 'Reverse Transaction',
  reversionDoneCorrectly: 'Reversion done correctly.',
  reviewValidationsIncompleteValidations:
    'Validations are required prior to loan disbursement',
  routingNumber: 'Routing Number',
  'Start date': 'Start Date',
  Tenor: 'Term',
  to: 'to',
  tranDateLabel: 'Transaction Date',
  transactionCompleted: 'Transaction completed',
  transactionSuccessful: 'Transaction processed successfully',
  undefined: 'Undefined',
  validated: 'Validated',
  'View loan': 'View Loan',
  waived: 'waived',
  Waived: 'Waived',
  writeAShortReverseTran:
    'Write a short description about why this transaction is getting reversed.',
  'You are all done!': 'You are all done!',
  successfullyDisbursed: 'Successfully disbursed.',
  successfullyDisbursedNotificationNotSent:
    'Successfully disbursed. Notification not sent.',
  successfullyDisbursedTasksWithoutUserAssignment:
    'Successfully disbursed. Tasks created without user assigned.',
  successfullyDisbursedNotificationNotSentTasksWithoutUserAssignment:
    'Successfully disbursed. Notification not sent. Tasks created without user assigned. ',
  debtCollectionActivityAdded: 'Debt collection activity added',
  debtCollectionActivitiesDontLoaded: 'Debt collection activities did not load',
  currentPaymentPlan: 'Current Payment Plan',
  inAnotherDisbursementFile:
    'The loans have already been disbursed in another file.',
  someLoansAlreadyHaveDisbursementFilesAssociated:
    'Some loans already have disbursement files associated with them.',
  disbursementFileIsNotAvailablePerBank:
    'The disbursement file is not available for the selected Bank account.',
  daysPastDue: 'Days Past Due',
  paymentMethods: 'Paymenth Methods',
  transactionCompletedNotificationSent:
    'Transaction completed notification sent',
  paymentStarDateRestructuringDate:
    'The payment start date cannot be less than the rescheduling date',
  restructuringDateCannotToday:
    'The rescheduling date cannot be later than today',
  unpaidInstallmentsRestructuring:
    'There cannot be unpaid installments at the time of rescheduling, delete the generated installments and try again',
  installmentsPaymentStartDate:
    'There cannot be installments with a date greater than the payment start date, delete the generated installments and try again',
  installmentsGeneratedWithRestructuringDate:
    'There cannot be installments generated with a date greater than the rescheduling date, delete the generated installments and try again',
  noGracePeriods: 'No grace periods are allowed for this product',
  maximunNumberRestructurings:
    'Cannot exceed the maximum number of reschedulings for this product.',
  exceedDaysRestructuring:
    'The term of the new schedule cannot exceed the parameterized days.',
  gracePeriodValuesnotValid: 'Grace period values are not valid.',
  confirmPayment: 'Confirm Payment',
  areYouSureYouWantToInitiateACHPayment:
    'Are you sure you want to initiate a payment usign ACHQ for this loan?',
  thereIsTransactionInPendingStatus: 'There is a transaction in pending status',
  nextPaymentDate: 'Next Payment Date',
  installmentAmount: 'Next Payment Amount',
  externalCode: 'Loan Code',
  installmentDate: 'Installment Date',
  payDate: 'Payment Date',
  payOffInfo: 'Payoff Information',
  currentPayment: 'Current',
  day: 'Day',
  paymentDate: 'Payment Date',
  dailyChange: 'Daily Change',
  sendEmailTo: 'Send Email to',
  principalContactEmail: 'Principal Contact Email',
  addEmailContacts: 'Add Email Contacts',
  emailContacts: 'Email Contact(s)',
  addEmailToSendDocument: 'Add Email(s) to Send Document',
  addedEmails: 'Added Email(s)',
  addEmail: 'Add Email',
  sendDocument: 'Send Document(s)',
  payOffNotGenerated: 'Payoff did not generate',
  notExistEmailForThisContact: 'Does not exist email for this contact',
  unexpectedErrorSendingEmail: 'Unexpected error sending email',
  payOffSentSuccessfully: 'Payoff sent successfully',
  payOffSentToAllEmailsSuccessfully: 'Payoff sent to all emails successfully',
  prepaymentPenalty: 'Prepayment Penalty',
  totalAmount: 'Total Amount',
  sendStatement: 'Send Statement',
  doesNotExistInstallmentsForThisLoan:
    'Does not exist installments for this loan',
  noCompleteDataForLoanStatement: 'No complete data for the loan statement',
  statementSentToAllEmailsSuccessfully:
    'Statement sent to all emails successfully',
  statementSentSuccessfully: 'Statement sent successfully',
  validationDisbursWithChargeDiscount:
    'The disbursement value cannot be less than the loan amount as the product has fees that will be disbursed at the loan amount.',
  quotaValues: 'Amount Due',
  paidQuotaValues: 'Amount Paid',
  transactions: 'Transactions',
  paymentMethod: 'Payment Method',
  paymentMethodCapital: 'Payment Method',
  dailyReport: 'Daily report',
  weeklyReport: 'Weekly report',
  payments: 'Payments',
  general: 'General',
  delinquentSince: 'Delinquent Since',
  amountToBePaid: 'Amount Due',
  delinquency: 'Delinquency',
  delinquencyStatus: 'Delinquency Status',
  delinquencyReport: 'Delinquency Report',
  delinquencyReports: 'Delinquency Reports',
  paymentProcessing: 'Payment Processing',
  extensionActive: 'Extensions',
  loansChargedOff: 'Charged-off',
  loansDeferral: 'Deferrals',
  noExistDelinquencyStatus: 'There is no exist this delinquency status',
  noTransactionInformation: 'There is no information about transactions',
  AUpdateDate: 'Approval Date',
  PUpdateDate: 'Start Date',
  RUpdateDate: 'Rejection Date',
  installmentPeriod: 'Installment Period',
  previousPrincipalBalance: 'Previous Principal Balance',
  currentPrincipalBalance: 'Current Principal Balance',
  dueDateCapital: 'Due Date',
  dailyPayments: 'Daily Payments',
  of: 'of',
  show: 'Details',
  notes: 'Notes',
  addNotes: 'Add Notes',
  subject: 'Subject',
  description: 'Description',
  noNotes: 'No notes registered',
  noStatements: 'No statements registered',
  loanNoteAddedSuccessfully: 'Note added successfully',
  noExistLoanNotes: 'There are no notes',
  noteAttachmentNotExist: 'There is no exist this file',
  loanReports: 'Loan Reports',
  loanTerms: 'Loan Terms',
  loanParticipation: 'Loan Participation',
  stateGuaranteeReport: 'State Guarantee',
  borrower: 'Borrower',
  loanStatus: 'Loan Status',
  chargedOff: 'Charged Off',
  chargedOffInformation: 'Charge Off Information',
  loanNumber: 'Loan Number',
  borrowerName: 'Borrower Name',
  firstPaymentDate: 'First Payment Date',
  monthlyPaymentAmount: 'Monthly Payment Amount',
  attachmentDeletedSuccessfully: 'Attachment successfully deleted',
  deleteAttachment: 'Delete attachment',
  loanNoteDeletedSuccessfully: 'Loan note successfully deleted',
  loanNoteUpdatedSuccessfully: 'Loan note updated successfully',
  loanNotesLoadedSuccessfully: 'Loan note loaded successfully',
  noExistAttachment: 'No exist attachment',
  startTyping: 'Start typing',
  deleteNote: 'Delete note',
  priorPrincipalBalance: 'Prior Principal',
  principalAmount: 'Principal',
  interestAmount: 'Interest',
  penaltyFees: 'Fees',
  totalPaid: 'Total Paid',
  paymentReport: 'Payment Report',
  showPaymentReport: 'Show Payment Report',
  reportSentToAllEmailsSuccessfully: 'Report sent to all emails successfully',
  reportNotGenerated: 'Payment report dit not generate',
  paymentReportSentSuccessfully: 'Payment report sent successfully',
  sendPaymentReport: 'Send payment report',
  paymentExecutionDate: 'Payment Execution Date',
  paymentExecutionDateCapital: 'Payment Execution Date',
  dateReceived: 'Received',
  noExistPrincipalEmailContact:
    'No main contact email found. Please set up a main contact.',
  areYouSureThisEmailContact:
    'Are you sure you want to send the document to this email?',
  fromDate: 'From',
  toDate: 'To',
  period: 'Period',
  pmtMethod: 'Method',
  fees: 'Fees',
  originalInvoice: 'Original Invoice',
  payment: 'Payment',
  downloadExcel: 'Download Excel format',
  downloadPdf: 'Download Pdf format',
  sendFileToEmails: 'Send file to email(s)',
  executed: 'Executed',
  received: 'Received',
  noDataFoundForReport: 'No data found for report',
  fileType: 'File type',
  yourLoan: 'Your Loan',
  charges: 'Charges',
  totalAmountDue: 'Total Amount Due',
  pastDueAmount: 'Past Due Amount',
  currentlyMonthlyDue: 'Currently Monthly Installment Due',
  installment: 'Installment',
  linkToPay: 'Link to Pay',
  copyLinkAndSendToCustomer: 'Copy link and send to customer',
  copy: 'Copy',
  thisIsLinkToPay: 'This is the link to pay',
  loanNotFound: 'Loan not found',
  paymentLinkCreatedSuccessfully: 'Payment link created successfully',
  termConditions: 'Terms and Conditions',
  newPayment: 'New payment',
  paymentLinkIsInvalid: 'The payment link is invalid',
  disagree: 'Disagree',
  agree: 'Agree',
  areYouSureUseThisService:
    'Are you sure you what to use this service to pay your loan?',
  paymentLink: 'Payment Link',
  downloadStatement: 'Download Statement Document',
  downloadPaymentPlan: 'Download Payment Plan',
  setAutoPaymentInfo: 'Set Auto-payment Info',
  setAutoPayment: 'Auto-payment Info',
  autoPaymentInfoUpdatedSuccessful: 'Auto-Payment Info Updated Successful',
  areYouSureSendLinkThisEmailContact:
    'Are you sure you want to send the link to update the auto-payment information to this email?',
  setPaymentInfoSentSuccessfully:
    'Link to update the auto-payment information sent successfully',
  sendLinkUpdateAutoPaymentInfo: 'Send link to update Auto-payment info',
  confirmSetAutoPayment: 'Confirm update Auto-payment',
  areYouSureYouWantToUpdateAutoPayment:
    'Are You Sure You Want To update Auto-payment Info?',
  paymentExtension: 'Payment Extension',
  dateForPaymentExtension: 'Date for Payment Extension',
  loanPaymentExtensionAddedSuccessfully:
    'Loan payment extension successfully added',
  extensions: 'Extensions',
  extensionAttachmentNotExist: 'Attachment Not Exist',
  loanPayments: 'Payments',
  active: 'Active',
  hasExtension: 'Extension',
  overdue1To29: 'Overdue 1 to 29',
  overdue30To59: 'Overdue 30 to 59',
  overdue60To89: 'Overdue 60 to 89',
  overdue90OrMore: 'Overdue 90 or more',
  useCurrentAccount: 'Use current account',
  noHasPaymentAccountConfigured: 'No has payment account configured',
  noHasAutoPaymentParametersConfig: 'No has auto-payment parameters configured',
  noPaymentExtensions: 'No payment extensions registered',
  isPartialPayment: 'Partial Payment',
  requiredPartialPaymentIsChecked:
    'Partial payment is checked, you must enter the amount',
  ifPartialPayPaymentAmountMustBeGreaterThatZero:
    'If partial payment, the amount must be greater than zero.',
  noPaymentExtensionConfigured: 'No payment extension configured',
  removeExtension: 'Remove Extension',
  loanPaymentExtensionDeletedSuccessfully:
    'Extension of payment successfully deleted',
  paymentLinkProcess: 'Payment Link',
  automaticPayment: 'Automatic Payment',
  createdByCapital: 'Created By',
  dateCreated: 'Date Created',
  approved: 'Approved',
  amountApplied: 'Amount Applied',
  statements: 'Statements',
  addStatement: 'Add Statement',
  loanStatementsLoadedSuccessfully: 'Loan Statement(s) loaded successfully',
  loanStatementAttachmentDeletedSuccessfully:
    'Loan Statement attachment deleted successfully',
  loanStatementAttachmentNotExist: 'Loan Statement not exist',
  loanStatementSavedSuccessfully: 'Loan Statement saved successfully',
  dateClosed: 'Closed Date',
  paymentLinkHasExpired:
    'Your payment link has expired! Contact us for a new payment link.',
  paymentLinkWillBeValid:
    'The payment link will be valid for 10 days, after which it will expire. You will have to contact us for a new payment link.',
  paymentLinkWillExpired: 'The payment link will expire',
  chargedOffAmount: 'Charged Off Amount',
  balanceAmount: 'Balance Amount',
  defaultDate: 'Default Date',
  numberOfPaymentsReceived: 'Number of Payments Received',
  numberOfPaymentsMissed: 'Number of Payments Missed',
  principalLoanBalance: 'Principal loan balance as of date of default',
  expensesIncurred: 'Expenses Incurred',
  repossession: 'Repossession',
  repairs: 'Repairs',
  Insurance: 'Insurance',
  otherExpensesIncurred: 'Other(s) Expenses Incurred',
  paymentCollected: 'Payments Collected',
  liquidationProceeds: 'Liquidation Proceeds',
  insuranceRefund: 'Insurance Refund',
  otherPaymentCollected: 'Other(s) Payments Collected',
  informationUpdatedSuccessfully: 'Information updated successfully',
  informationReportIsEmpty: 'Information report is empty',
  editChargeOffInformation: 'Edit Charge Off Information',
  markAsSold: 'Mark as Sold',
  areYouSureYouWantMarkLoanAsSold:
    'Are you sure you want to mark the loan as sold?',
  sold: 'Sold',
  processed: 'Processed',
  initialPeriod: 'Initial Period',
  beginningPrincipalBalance: 'Beginning Balance',
  interest: 'Interest',
  installments: 'Total Installments',
  endingPeriod: 'Ending Period',
  endingPrincipalBalance: 'Ending Balance',
  loansReceivable: 'Loans Receivable',
  totalPayments: 'Total Payments',
  accountsReceivableAging: 'Accounts Receivable Aging',
  agingMethod: 'Aging Method',
  current: 'Current',
  andOver: 'and Over',
  numberOfPeriods: 'Number Of Periods',
  periods: 'Periods',
  amountAppliedCapital: 'Amount Applied',
  pendingAmountCapital: 'Pending Amount',
  portfolioBalance: 'Portfolio Balance',
  method: 'Method',
  totalPrincipal: 'Total Principal',
  totalInterest: 'Total Interest',
  totalFees: 'Total Fees',
  paid: 'Paid',
  loanClosure: 'Loan Closure',
  files: 'Files',
  guaranteePaymentRequested: 'Guarantee Payment Requested',
  batchNumber: 'Batch Number',
  purchaseDate: 'Purchase Date',
  purchaseAmount: 'Purchase Amount',
  premiumAmountPaid: 'Premium Amount Paid',
  numberOfPaymentsMade: 'Number of Payments Made',
  paymentsOfPrincipalMade: 'Payments of Principal Made',
  principalBalanceDue: 'Principal Balance Due',
  replaceOrMakeWhole: 'Replace or Make Whole',
  editLoanParticipation: 'Edit Loan Participation',
  iBankLoanNumber: 'IBank Loan Number',
  guaranteeAssignmentDate: 'Guarantee Assignment Date',
  dateDemandLetterSubmitted: 'Date Demand Letter Submitted',
  dateOfLastPaymentReceived: 'Date of Last Payment Received',
  defaultCalculation: 'Default Calculation',
  principalBalanceDueAtDefault: 'Principal Balance Due at Default',
  repossessionExpenses: 'Repossession Expenses',
  adjustedPrincipalAmount: 'Adjusted Principal Amount',
  ninetyDaysOfInterest: '90 Days of Interest',
  totalLoss: 'Total Loss',
  editLoanStateGuarantee: 'Edit Loan State Guarantee',
  guaranteedPrincipalAmount: 'Guaranteed Principal Amount',
  interestAmountDue: 'Interest Amount Due',
  totalGuaranteePaymentRequested: 'Total Guarantee Payment Requested',
  amountReceivedFromState: 'Amount Received from State',
  unrecoveredLosses: 'Unrecovered Losses',
  editLoanGuaranteePaymentRequested: 'Edit Guarantee Payment Requested',
  guaranteedPrincipalPercent: 'Guaranteed Principal Percent',
  interestAmountDuePercent: 'Interest Amount Due Percent',
  reportNotExist: 'This report does not exist',
  loanClosureFilesSavedSuccessfully: 'Closure files saved successfully',
  loanClosureFilesNotExist: 'Closure files do not exist',
  loadedSuccessfully: 'Loaded successfully',
  reportGenerated: 'Report generated',
  loanClosureFileDeletedSuccessfully: 'Closure file deleted successfully',
  uploadedFiles: 'Uploaded File(s)',
  deleteFile: 'Delete File',
  notificationSentSuccessfully: 'Notification sent successfully',
  readyToChargedOff: 'Ready to be charged off',
  noFilesUploaded: 'No file(s) uploaded',
  sendNotification: 'Send Notification',
  areYouSureYouWantSendNotification:
    'Are you sure you want to send the notification?',
  youMustUploadLoanClosureFiles: 'You must upload loan closure files',
  'One Dark': 'One Dark',
  Acelera: 'Acelera',
  accounting: 'Accounting',
  dashboard: 'Dashboard',
  accountsReceivableSummary: 'Accounts Receivable Summary',
  yearToDateLoansReceivableSummary: 'Year to Date Loans Receivable Summary',
  dashboardOverview: 'Overview',
  numberOfLoansFunded: 'Number of Loans Funded',
  totalLoanPortfolioValue: 'Total Loan Portfolio Value',
  averageLoanAmount: 'Average Loan Amount',
  averageInterestRate: 'Average Interest Rate',
  averageOriginationFee: 'Average Origination Fee',
  averageFicoCreditScore: 'Average FICO Credit Score',
  loanBuyerOverview: 'Loan Buyer Overview',
  numberOfLoansSold: 'Number Of Loans Sold',
  valueOfLoansSold: 'Value of Loans Sold',
  averagePremiumAmount: 'Average Premium Amount',
  expirationDateUpdatedSuccessfully: 'Expiration date updated successfully',
  insuranceExpirationInformation: 'Insurance Expiration Information',
  showInsuranceExpirationInformation: 'Show Insurance Expiration Information',
  insuranceCompanyName: 'Insurance Company',
  insuranceBrokerName: 'Insurance Broker Name',
  insuranceBrokerEmail: 'Insurance Broker Email',
  insuranceBrokerPhone: 'Insurance Broker Phone',
  insuranceExpirationDateCapital: 'Insurance Expiration Date',
  noExistInsuranceExpirationInfo:
    'Does not exist Insurance Expiration Information',
  insuranceExpirationCreateOrUpdatedSuccessfully:
    'Insurance Expiration create or updated successfully',
  vinCode: 'VIN Code',
  insuranceAmount: 'Insurance Amount',
  hasCollectionActivity:
    'This contact has collection notifications. You can not delete it',
  inputDateLastInstallment: 'Should be the date of the last installment paid',
  numberInstallmentRemainingAddingGracePeriod:
    'Input the number of installments remaining adding the grace period(s)',
  inputDateInstallmentToBeDeffered:
    'Input the date of the installment to be deffered',
  inputNumberOfGracePeriod:
    'Input the number of the grace period to be granted',
  liquidatedCollateralAmount: 'Liquidated Collateral Amount',
  accruedInterestAmount: 'Accrued Interest Amount',
  downloadDemandLetter: 'Download Demand Letter',
  demandLetterSentToAllEmailsSuccessfully:
    'Demand Letter sent to all emails successfully',
  defaultAndRepossession: 'Default and Repossession',
  chargedOffDate: 'Charged Off Date',
  reasonForChargeOff: 'Reason for Charge Off',
  repossessionDate: 'Repossession Date',
  repossessionType: 'Repossession Type',
  repossessionCompanyName: 'Repossession Company Name',
  repossessionCost: 'Repossession Cost',
  conditionOfCollateral: 'Condition of Collateral',
  priceDigestValue: 'Price Digest Value',
  liquidationDate: 'Liquidation Date',
  liquidationAmount: 'Liquidation Amount',
  nameOfBuyer: 'Name of Buyer',
  voluntary: 'Voluntary',
  involuntary: 'Involuntary',
  good: 'Good',
  poor: 'Poor',
  damaged: 'Damaged',
  totalLost: 'Total Lost',
  attachConditionReport: 'Attach Condition report',
  attachPriceDigestReport: 'Attach Price Digest report',
  attachBillSaleAndInvoice: 'Attach Bill of Sale And Invoice',
  notSelected: 'Not Selected',
  noExistDefaultAndRepossession: 'No exist Default and Repossession',
  informationSavedSuccessfully: 'Information saved successfully',
  attachmentsTo: 'Attachment(s) to ',
  editDefaultAndRepossession: 'Edit Default and Repossession',
  loanSaleDetails: 'Sale Details',
  amountPurchased: 'Amount Purchased',
  continuePaymentOrMakeWhole: 'Continue Payment or Make Whole',
  continuePayment: 'Continue Payment',
  makeWhole: 'Make Whole',
  editLoanSaleDetails: 'Edit Sale Details',
  investorName: 'Name',
  getDataFromOpEx: 'Get data from OpEx',
  getSaleDetailsFromExternalService: 'Get Sale Details from external service',
  loanHasNotBeenSoldYet: 'The loan has not been sold yet',
  stateGuaranteeNotFound: 'State Guarantee did not find',
  dateDemandLetterSubmittedNotExist:
    'Date Demand Letter Submitted does not exist',
  dateDemandLetterIsLessThanDateLastPaymentMade:
    'Date Demand Letter is less than Date Last Payment Made',
  getSaleDetails: 'Get Sale Details',
  dateOfLastPaymentMadeToInvestor: 'Date of Last Payment Made to Investor',
  investor: 'Investor',
  investorAddress: 'Address',
  investorEmail: 'Email',
  downloadDraftNoticeDefaultInvestorV1:
    'Download Draft Notice Loan Default to Investor V1',
  dateOfNoticeLoanDefaultToInvestor: 'Date of Notice Loan Default to Investor',
  sendDraftDocumentToEmails: 'Send Draft Document to Emails',
  signOffOnAmountsAndCalculations: 'Sign Off on Amounts and Calculations',
  areYouSureYouWantToSignOffOnAmountsAndCalculations:
    'Are you sure you want to sign off on Amounts and Calculations?',
  saleDetailsNotFound: 'Sale Details not found',
  dateOfLastPaymentMadeToInvestorNotExist:
    'Date of Last Payment Made to Investor does not exist',
  noExistNotification: 'Notification not exist',
  noticeLoanDefaultSentToAllEmailsSuccessfully:
    'Notice Loan Default sent to all emails successfully',
  fileSavedSuccessfully: 'File saved successfully',
  sendNoticeLoanDefaultToInvestor: 'Send Notice Loan Default to Investor',
  sendNoticeToInvestor: 'Send Notice to Investor',
  areYouSureYouWantToSendNoticeToInvestor:
    'Are you sure you want to send notice to Investor?',
  investorEmailIsNotValid: 'Investor email is not valid',
  noticeLoanDefaultSentSuccessfully: 'Notice Loan Default sent successfully',
  emailSentToServicingAndFileSavedSuccessfully:
    'Email sent to servicing and file saved successfully',
  fileSavedSuccessfullyErrorSendingServicingEmail:
    'File saved successfully but, with an error sending Servicing email',
  downloadDraftDemandLetter: 'Download the Draft Demand Letter',
  signOnDemandLetter: 'Sign Off on the Demand Letter',
  areYouSureYouWantToSignOnDemandLetter:
    'Are you sure you want to sign off on the Demand Letter?',
  sendSignedDocumentToEmails: 'Send Signed Document to Email(s)',
  addEmailsToSendDocumentAsToAndCc:
    'Add Email(s) to Send Document(s) as To and Cc',
  addEmailAsTo: 'Add Email(s) as To',
  addedEmailsAsTo: 'Added Email(s) as To',
  emailAsTo: 'Email as To',
  addEmailAsCc: 'Add Email(s) as Cc',
  addedEmailsAsCc: 'Added Email(s) as Cc',
  emailAsCc: 'Email as Cc',
  insuranceReport: 'Insurance',
  expirationDate: 'Expiration Date',
  fortyFiveDays: '45 Days',
  thirtyDays: '30 Days',
  tenDays: '10 Days',
  zeroDays: '0 Day',
  insuranceNoteAddedSuccessfully: 'Insurance Note added successfully',
  noExistInsuranceNote: 'No exist Insurance Note',
  insuranceNoteUpdatedSuccessfully: 'Insurance Note updated successfully',
  insuranceNoteDeletedSuccessfully: 'Insurance Note deleted successfully',
  insuranceNoteAttachmentNotExist: 'There is no exist this file',
  insurance: 'Insurance',
  addInsuranceNote: 'Add Insurance Note',
  deleteInsuranceNote: 'Delete Insurance Note',
  noInsuranceNotes: 'No insurance notes registered',
  expired: 'Expired',
  sent: 'Sent',
  buyerInformation: 'Buyer Information',
  state: 'State',
  zip: 'Zip',
  vehicleCollateralDetails: 'Vehicle Collateral Details',
  vin: 'Vin',
  licensePlate: 'License Plate',
  saleAmount: 'Sale Amount',
  saleDate: 'Sale Date',
  billOfSale: 'Bill of Sale',
  downloadDraftBillOfSale: 'Download Draft Bill of Sale',
  editBillOfSale: 'Edit Bill of Sale',
  downloadDraftInvoice: 'Download Draft Invoice',
  documentsSentToAllEmailsSuccessfully:
    'Document(s) sent to all email(s) successfully',
  billOfSaleNotFound: 'Bill of Sale not found',
  emailSubject: 'Subject',
  emailBody: 'Body',
  generateAndSend: 'Generate and Send Documents',
  areYouSureYouWantToSendFiles: 'Are you sure you want to send the file(s)?',
  confirmSendFilesToEmails: 'Confirm Send Files To Email(s)',
  created: 'Created',
  updated: 'Updated',
  downloadFilteredReport: 'Download filtered report',
  downloadActiveInsuranceReport: 'Download active Insurance report',
  insPolicyOnFile: 'Ins. Policy on File',
  buyer: 'Buyer',
  invoiceNumber: 'Invoice Number',
  invoice: 'Invoice',
  collateralReport: 'Collateral',
  downloadAllCollateralReport: 'Download all Collateral report',
  coi: 'COI',
  paymentCycle: 'Payment Cycle',
  cycle1st: '1st',
  cycle15th: '15th',
  downloadDraftNoticeDefaultInvestor:
    'Download Draft Notice Loan Default to Investor',
  version: 'Version',
  downloadDraftNoticeDefaultInvestor:
    'Download Draft Notice Loan Default to Investor',
  defaultedLoansReport: 'Defaulted Loans',
  ibank: 'IBank',
  submitted: 'Submitted',
  initialLoan: 'Initial Loan',
  defaultedBalance: 'Defaulted Balance',
  principalPaid: 'Principal Paid',
  pmtsMade: 'Pmts Made',
  defaulted: 'Defaulted',
  equipment: 'Equipment',
  initial: 'Initial',
  liquidation: 'Liquidation',
  monthOfDefault: 'Month of Default',
  daysToReceiveStateGuarantee: 'No of Days to Receive State Guarantee',
  downloadAllLoansClosureReport: 'Download all loans closure report',
  eQLoanListReport: 'Sold Loans',
  contract: 'Contract',
  customerName: 'Customer Name',
  financing: 'Financing',
  downPmt: 'Down Pmt',
  closing: 'Closing',
  date: 'Date',
  int: 'Int',
  int: 'Int',
  batch: 'Batch',
  monthlyPmt: 'Monthly Pmt',
  loanSold: 'Loan Sold',
  loanSales: 'Loan Sales',
  date: 'Date',
  pd: 'Pd.',
  statementCycle: 'Statement Cycle',
  percentSold: 'Percent Sold',
  premiumFee: 'Premium Fee',
  servicingFee: 'Servicing Fee',
  acqEffective: 'Acq. Effective',
  balance: 'Balance',
  premium: 'Premium',
  loanPurchase: 'Loan Purchase',
  investment: 'Investment',
  mthPmt: 'Mth Pmt',
  irr: 'IRR',
  calculateIrr: 'Calculate IRR',
  dummy: 'Dummy',
  periodPurchased: 'Purchased Period',
  successfullyButErrorInOpex: 'Successfully, but there was an error in Opex',
  transactionDetails: 'Transaction Details',
  installmentDetails: 'Installment Details',
  termDate: 'Term',
  filteredBy: 'Filter by',
  amountDue: 'Amount Due',
  dateNotice: 'Notice Date',
  stateGuaranteeLoans: 'IBank Loans',
  numberOfDefaultedLoans: 'Number of Defaulted Loans',
  defaultRate: 'Default Rate',
  ibankAmount: 'IBank Received Amount',
  calCapLoans: 'CalCAP SB Loans',
  noRegistered: 'No Registered',
  showLoansFunded: 'Show Loans Funded',
  showDefaultedLoans: 'Show Defaulted Loans',
  showLoansSold: 'Show Loans Sold',
  daysLate: 'Days Late',
  creditEnhancementType: 'Credit Enhancement Type',
  stateGuarantee: 'IBank',
  calCap: 'CalCAP SB',
  ineligible: 'Ineligible',
  areYouSureYouWantMarkLoanAsIneligible:
    'Are you sure you want mark loan as ineligible?',
  markAsIneligible: 'Mark as Ineligible',
  loanMarkedAsIneligible: 'Loan marked as ineligible',
  downloadLoanClosureMemo: 'Download Loan Closure Memo',
  searchLoans: 'Search Loans',
  portfolioLoans: 'Loan Portfolio',
  activeLoans: 'Active Loans',
  notifyAccounting: 'Notify Accounting',
  sendNotificationToAccounting: 'Send Notification to Accounting',
  saveToSend: 'Save to send',
  sendNoticeDefaultLackInsurance:
    'Send Notice of Default Due to Lack of Insurance',
  downloadNoticeDefaultLackInsurance:
    'Download Notice of Default Due to Lack of Insurance',
  emailSentAndSavedSuccessfully: 'Email sent and saved successfully',
  fileSavedSuccessfullyErrorSendingEmail:
    'File saved successfully, with error sending the email',
  areYouSureYouWantToSendNoticeDefaultLackInsurance:
    'Are you sure you want to send the Notice Default Lack Insurance?',
  voluntaryCollateralRelease: 'Voluntary Collateral Release',
  dateInformedVoluntaryReturn: 'Date Informed Voluntary Return',
  sendVoluntaryCollateralReleaseLetter:
    'Send Voluntary Release of Collateral Letter',
  downloadVoluntaryCollateralReleaseLetter:
    'Download Voluntary Release of Collateral Letter',
  areYouSureYouWantToSendVoluntaryCollateralReleaseLetter:
    'Are you sure you want to send the Voluntary Release of Collateral Letter?',
  noticeLoanDefault: 'Notice Loan Default',
  categoryFile: 'Category File(s)',
  uploadedByUser: 'Uploaded by User',
  demandLetter: 'Demand Letter',
  watchList: 'Watch List',
  monthlyPaymentDueDate: 'Monthly Payment Due Date',
  totalPastDueAmount: 'Total Past Due Amount',
  call: 'Call',
  sendNotifications: 'Send Notifications',
  amountInProcess: 'Amount in Process',
  signOff: 'Approve',
  nsf: 'NSF',
  borrowerProfileData: 'Borrower Profile Data',
  ficoScore: 'FICO Score',
  minFicoScore: 'Min FICO Score',
  maxFicoScore: 'Max FICO Score',
  yearsInBusiness: 'Years in Business',
  minYearsInBusiness: 'Min Years in Business',
  maxYearsInBusiness: 'Max Years in Business',
  downPaymentPercentage: 'Down Payment Percentage',
  minDownPaymentPercentage: 'Min Down Payment Percentage',
  maxDownPaymentPercentage: 'Max Down Payment Percentage',
  totalMonthlyBankDeposits:
    'Total Monthly Bank Deposits (3-month average) at Origination',
  minTotalMonthlyBankDeposits: 'Min Total Monthly Bank Deposits',
  maxTotalMonthlyBankDeposits: 'Max Total Monthly Bank Deposits',
  numberOfPreviousLoans: 'Number of Previous Loans',
  minNumberOfPreviousLoans: 'Min Number of Previous Loans',
  maxNumberOfPreviousLoans: 'Max Number of Previous Loans',
  typeOfBusiness: 'Type of Business Entity',
  loanCharacteristics: 'Loan Characteristics',
  loanAmount: 'Loan Amount',
  minLoanAmount: 'Min Loan Amount',
  maxLoanAmount: 'Max Loan Amount',
  loanTerm: 'Loan Term (months)',
  minLoanTerm: 'Min Loan Term',
  maxLoanTerm: 'Max Loan Term',
  interestRate: 'Interest Rate',
  minInterestRate: 'Min Interest Rate',
  maxInterestRate: 'Max Interest Rate',
  monthlyPaymentAmount: 'Monthly Payment Amount',
  minMonthlyPaymentAmount: 'Min Monthly Payment Amount',
  maxMonthlyPaymentAmount: 'Max Monthly Payment Amount',
  loanToValueRatioAtOrigination: 'Loan to Value Ratio at Origination',
  minLoanToValueRatioAtOrigination: 'Min Loan to Value',
  maxLoanToValueRatioAtOrigination: 'Max Loan to Value',
  originationFeeCharged: 'Origination Fee Charged',
  minOriginationFeeCharged: 'Min Origination Fee Charged',
  maxOriginationFeeCharged: 'Max Origination Fee Charged',
  timeFromApplicationToFunding: 'Time from Application to Funding',
  minTimeFromApplicationToFunding: 'Min Time from Application to Funding',
  maxTimeFromApplicationToFunding: 'Max Time from Application to Funding',
  collateralInformation: 'Collateral Information',
  vehicleType: 'Vehicle Type',
  vehicleAgeAtOrigination: 'Vehicle Age at Origination',
  minVehicleAgeAtOrigination: 'Min Vehicle Age',
  maxVehicleAgeAtOrigination: 'Max Vehicle Age',
  vehicleMileageAtOrigination: 'Vehicle Mileage at Origination',
  minVehicleMileageAtOrigination: 'Min Vehicle Mileage',
  maxVehicleMileageAtOrigination: 'Max Vehicle Mileage',
  vehicleManufacturer: 'Vehicle Manufacturer',
  originalPurchasePrice: 'Original Purchase Price',
  minOriginalPurchasePrice: 'Min Original Purchase Price',
  maxOriginalPurchasePrice: 'Max Original Purchase Price',
  typeOfHauling: 'Type of Hauling',
  paymentPerformance: 'Payment Performance',
  numberOfLatePayments: 'Number of Late Payments (lifetime)',
  minNumberOfLatePayments: 'Min Number of Late Payments',
  maxNumberOfLatePayments: 'Max Number of Late Payments',
  averageDaysLatePerPayment: 'Average Days Late per Payment',
  minAverageDaysLatePerPayment: 'Min Average Days Late per Payment',
  maxAverageDaysLatePerPayment: 'Max Average Days Late per Payment',
  numberOfNSF: 'Number of NSF (non-sufficient funds) Instances',
  minNumberOfNSF: 'Min Number of NSF',
  maxNumberOfNSF: 'Max Number of NSF',
  numberOfLoanExtensionsRequested: 'Number of Loan Extensions Requested',
  minNumberOfLoanExtensionsRequested: 'Min Number of Loan Extensions Requested',
  maxNumberOfLoanExtensionsRequested: 'Max Number of Loan Extensions Requested',
  numberOfLoanExtensionsGranted: 'Number of Loan Extensions Granted',
  minNumberOfLoanExtensionsGranted: 'Min Number of Loan Extensions Granted',
  maxNumberOfLoanExtensionsGranted: 'Max Number of Loan Extensions Granted',
  numberOfLoanDeferralsRequested: 'Number of Loan Deferrals Requested',
  minNumberOfLoanDeferralsRequested: 'Min Number of Loan Deferrals Requested',
  maxNumberOfLoanDeferralsRequested: 'Max Number of Loan Deferrals Requested',
  numberOfLoanDeferralsGranted: 'Number of Loan Deferrals Granted',
  minNumberOfLoanDeferralsGranted: 'Min Number of Loan Deferrals Granted',
  maxNumberOfLoanDeferralsGranted: 'Max Number of Loan Deferrals Granted',
  longestConsecutiveStreak: 'Longest Consecutive Streak',
  minLongestConsecutiveStreak: 'Min Longest Consecutive Streak',
  maxLongestConsecutiveStreak: 'Max Longest Consecutive Streak',
  numberOfInsuranceLapses: 'Number of Insurance Lapses',
  minNumberOfInsuranceLapses: 'Min Number of Insurance Lapses',
  maxNumberOfInsuranceLapses: 'Max Number of Insurance Lapses',
  numberOfAchAutoDebitFailures: 'Number of ACH Auto Debit Failures',
  minNumberOfAchAutoDebitFailures: 'Min Number of ACH Auto Debit Failures',
  maxNumberOfAchAutoDebitFailures: 'Max Number of ACH Auto Debit Failures',
  dryVan: 'Dry Van',
  flatbed: 'Flatbed',
  reefer: 'Reefer',
  riskManagement: 'Risk Management',
  createRiskManagement: 'Create Risk Management Filter',
  editRiskManagement: 'Edit Risk Management Filter',
  newRiskManagementFilter: 'New Risk Management Filter',
  riskManagementFilterToDelete: 'Risk Management Filter to Delete',
  areYouSureYouWantToDeleteTheRiskManagementFilter:
    'Are you sure you want to delete this filter?',
  riskManagementFilterSavedOrUpdatedSuccessfully:
    'Risk Management Filter saved or updated successfully',
  riskManagementFilterDeletedSuccessfully:
    'Risk Management Filter deleted successfully',
  riskManagementFilterNoFound: 'Risk Management Filter no found',
  totalMonthlyBankDepositsTable: 'Total Monthly Bank Deposits',
  numberOfNSFTable: 'Number Of NSF',
  default: 'Default',
  loanManagementReports: 'Loan Management Reports',
  interestPayment: 'Interest Payment',
  downloadInterestPaymentDocument: 'Download Interest Payment Document',
  sendInterestDocumentToPrincipalContact:
    'Send Interest Document to Principal Contact',
  interestPaymentDocumentSentSuccessfully:
    'Interest payment document sent successfully',
  interestPaymentDocumentSentToAllEmailsSuccessfully:
    'Interest payment document sent to all emails successfully',
  historicalDataTape: 'Historical Data Tape',
  folderLink: 'Link to Folder',
  downloadReport: 'Download report',
};

export const loanDetailsEs = {
  'A transaction still exists on this loan':
    'Aún existe una transacción en este préstamo',
  transactionCompletedNotificationDidNotSend:
    'Transaccion completada. Notificación no enviada.',
  accountId: 'ID de cuenta',
  accountToken: 'Token de cuenta',
  accountNumber: 'Número de Cuenta',
  activity: 'Actividad',
  Activity: 'Actividad',
  activityDate: 'Fecha de actividad',
  agreementDate: 'Fecha de acuerdo',
  alreadyPaid: 'Ya pagó',
  areYouSureYouWantMarkLoanForSale:
    '¿Está seguro de marcar préstamo para venta?',
  autoPayment: 'Pago Automático',
  autoPaymentParameters: 'Parametrización de Pagos Automaticos',
  availableForSale: 'Disponible para venta',
  bankAccount: 'Cuenta bancaria',
  bankName: 'Nombre del Banco',
  'Change rate': 'Cambiar tasa',
  chargesAddedValueLoan: 'Cargos agregados al valor del prestamo',
  chargesDeductedDisbursement: 'Cargos deducidos al desembolso',
  changeLoanStatusDescription: ' Seleccione Estado de Destino',
  changeStatus: 'Cambiar Estado',
  'Charge amount': 'Valor de cargos',
  company: 'Empresa',
  Complete: 'Completar',
  currentStatus: 'Estado Actual',
  customerDetails: 'Detalles de cliente',
  'Delete loan installment': 'Borrar cuota de préstamo',
  destinationState: 'Estado de Destino',
  disbAmountLabel: 'Valor de desembolso',
  disbAmtExceedsLoanAmt: 'El valor a desembolsar excede el valor del préstamo.',
  generateDisbursementFile: 'Generar archivo de desembolsos',
  disburse: 'Desembolsar',
  disbursedAmount: 'Monto desembolsado',
  disburseLabelBtn: 'Desembolsar',
  disburseLoan: 'Desembolsar Préstamo',
  'Disbursement transaction made.': 'Operación de desembolso realizada.',
  disbursementDate: 'Fecha de desembolso',
  dontAnswer: 'Sin respuesta',
  download: 'Descargar',
  downloadDisbFileSubTtl:
    'Seleccione la cuenta y la fecha para generar el archivo de desembolsos',
  downloadDisbFileTtl: 'Descargar archivo de desembolsos',
  loanEffectiveDate: 'Fecha efectiva',
  loanEffectiveDateCapital: 'Fecha Vigencia',
  paymentDueDate: 'Vencido',
  enterCustomerNameIdentification:
    'Ingrese nombre o número de cédula del cliente',
  enterProductNameId: 'Ingrese número o nombre del producto',
  entity: 'Entidad',
  EquatedInstallments: 'Cuotas iguales',
  evidences: 'Evidencias',
  'Existe un préstamo activo para la solicitud.':
    'Existe un préstamo activo para la solicitud.',
  fileDeleted: 'Archivo eliminado',
  filesAreRequired: 'Los archivos son requeridos',
  from: 'de',
  getLoanInfoFailed: 'Información del préstamo no encontrada',
  'Grace period': 'Periodo de gracia',
  'Grace period for': 'Periodo de gracia para',
  'Grace periods': 'Periodos de gracia',
  'Grace periods apply for': 'Periodos de gracia aplican para',
  'Has grace period?': '¿Tiene periodo de gracia?',
  inactive: 'Inactivo',
  incorrectNumber: 'Número incorrecto',
  'Installment amount': 'Valor de cuota',
  totalDueAmount: 'Valor total adeudado',
  'Installment of': 'Cuota del',
  'Installment type': 'Tipo de cuota',
  'Interest amount': 'Valor de intereses',
  interestRate: 'Tasa de interés',
  'La fecha inicial de pago no puede ser menor a la fecha de la transacción. ':
    'La fecha inicial de pago no puede ser menor a la fecha de la transacción.',
  'La solicitud debe estar en estado Iniciada para poder ser borrada. ':
    'La solicitud debe estar en estado Iniciada para poder ser borrada.',
  lastTranDisbError:
    'La fecha del desembolso no puede ser menor a la fecha de la última transacción.',
  loan: 'Préstamo',
  Loan: 'Préstamo',
  'Loan details': 'Detalles de préstamo',
  'Loan Rescheduling': 'Re-estructuración de préstamo',
  loanCode: 'Código del préstamo',
  loanCodeCapital: 'Código Préstamo',
  loanAmount: 'Monto del préstamo',
  prLoanAmount: 'Monto del Préstamo',
  loanAvailableForSale: 'Préstamo disponible para venta',
  loanChanged: 'Se ha cambiado estado del Préstamo',
  loanChangeNoAllowed: 'Cambio de estado no es permitido',
  loanDetails: 'Detalles de préstamo',
  loanInstallment: 'Plazo del préstamo',
  loanManagement: 'Administrar prestamo',
  loanNoFound: 'Préstamo no encontrado',
  loanRequest: 'Solicitud de préstamo',
  loanRequestValidations: 'Validaciones de solicitudes de préstamo',
  paymentMethods: ' Métodos de pago',
  loanlist: 'Portafolio de préstamos',
  loansId: 'ID',
  loanToFactorDetails: 'Detalles del préstamo a la fábrica',
  loanTransaction: 'Transacción de préstamo',
  loanTransactions: 'Transacciones de Préstamo',
  loanValidations: 'Validaciones del préstamo',
  loanWithNoRepaymentSchedule: 'Préstamo sin calendario de amortización',
  markForSale: 'Marcar para venta',
  noAllowedTransactions:
    'Esta transacción no está permitida para préstamos con este estado',
  noAllowedTransactionsMenu: 'Transacciones no permitidas',
  noContentFile: 'No se encontraron archivos de desembolsos.',
  noInvestorForLoan:
    'No se encontraron inversionistas para asignar al préstamo.',
  noMultipleDisbAllowed:
    'No se permiten varios desembolsos para el producto de préstamo seleccionado.',
  NonEquatedInstallments: 'Cuotas no iguales',
  notAbleToPay: 'Sin capacidad de pago',
  notValidated: 'No validado',
  numberInstallmentsMustGreaterEqualOne:
    'El número de cuotas debe ser mayor o igual a 1',
  numberInstallmentsExceedMaximumDate:
    'El numero de cuotas superan la fecha maxima 2100-01-01',
  'Number of installments': 'Número de cuotas',
  numberOfInstallments: 'Número de cuotas',
  observations: 'Observaciones',
  'Overdue date': 'Fecha de vencimiento',
  Overview: 'Vista General',
  pay: 'Pagar',
  Pay: 'Pagar',
  'Paid amount': 'Valor pagado',
  paidAmount: 'Valor pagado',
  totalPaidAmount: 'Valor total pagado',
  'Paid interest amount': 'Valor pagado de intereses',
  paidInterestAmount: 'Valor pagado de intereses',
  'Paid penal amount': 'Valor pagado de mora',
  paidPenalAmount: 'Valor pagado de mora',
  'Paid principal amount': 'Valor pagado de capital',
  paidPrincipalAmount: 'Valor pagado de capital',
  paidChargeAmount: 'Valor pagado de cargos',
  'Payment start date': 'Fecha de inicio de pago',
  paymentStartDate: 'Fecha de inicio de pago',
  paymentMethodDisburse: 'Medio de Desembolso',
  paymentPlan: 'Plan de pagos',
  paymentPlanRestructured: 'Plan de pagos reestructurado',
  'Penal amount': 'Valor de mora',
  penalRate: 'Tasa de mora',
  pendingAmount: 'Monto pendiente',
  outstandingPrincipal: 'Pendiente',
  pendingPrincipal: 'Capital pendiente',
  outstandingBalance: 'Saldo pendiente',
  pendingLoanInstallmentsExist:
    'Hay cuotas pendientes con fecha anterior a la fecha del desembolso.',
  'Principal amount': 'Valor capital',
  productType: 'Tipo de producto',
  pymtLessFromDateError:
    'La fecha de inicio de pago no puede ser menor a la fecha de inicio de re-estructuración.',
  pymtStartDateLabel: 'Fecha de inicio de pago',
  insurancePaymentDate: 'Fecha de pago del seguro',
  insuranceExpirationDate: 'Fecha de vencimiento del seguro',
  pymtStartDateNotLessThanDisbDate:
    'La fecha de inicio de pago no puede ser anterior a la fecha de desembolso.',
  'Raised?': '¿Generada?',
  'Re-schedule': 'Re-estructurar',
  'Record deleted successfully': 'Registro eliminado correctamente',
  registerPaymentReceived: 'Registrar pago',
  reluctantToPay: 'Renuente al pago',
  Reschedule: 'Re-estructurar',
  'Reschedule from date': 'Re-estructurar desde',
  rescheduleFromDate: 'Re-estructurar desde',
  'Rescheduling parameters': 'Parámetros de re-estructuración',
  reschedulingParametersr: 'Parámetros de re-estructuración',
  'Rescheduling successful message':
    'La re-estructuración del plan de pagos del préstamo ha sido completada exitosamente.',
  reverseLblBtn: 'Revertir',
  reverseTransaction: 'Revertir Transacción',
  reversionDoneCorrectly: 'Reversión realizada correctamente.',
  reviewValidationsIncompleteValidations:
    'Las validaciones son requeridas antes de desembolsar el préstamo',
  routingNumber: 'Número de Ruta',
  'Start date': 'Fecha inicial',
  Tenor: 'Plazo',
  to: 'a',
  tranDateLabel: 'Fecha de transacción',
  transactionCompleted: 'Transacción completada',
  transactionSuccessful: 'Transacción exitosa',
  undefined: 'Sin definir',
  validated: 'Validado',
  'View loan': 'Ver préstamo',
  waived: 'desistido',
  Waived: 'Desistido',
  writeAShortReverseTran:
    'Escriba una breve descripción sobre porqué se está revirtiendo esta transacción.',
  'You are all done!': '¡Ya está completado!',
  successfullyDisbursed: 'Desembolsado exitosamente.',
  successfullyDisbursedTasksWithoutUserAssignment:
    'Desembolsado exitosamente. Tareas creadas sin usuario asignado',
  successfullyDisbursedNotificationNotSent:
    'Desembolsado exitosamente. Notificación no enviada.',
  successfullyDisbursedNotificationNotSentTasksWithoutUserAssignment:
    'Desembolsado exitosamente. Notificación no enviada. Tareas creadas sin usuario asignado',
  debtCollectionActivityAdded:
    'Gestión de cobro de deuda agregada correctamente.',
  debtCollectionActivitiesDontLoaded:
    'No fue posible consultar la información de gestión de cobro.',
  inAnotherDisbursementFile:
    'Los prestamos ya fueron generados en otro archivo.',
  someLoansAlreadyHaveDisbursementFilesAssociated:
    'Algunos prestamos ya tiene archivos de desembolso asociado.',
  historicalPaymentPlan: 'Histórico plan de pago ',
  currentPaymentPlan: 'Plan de pagos actual',
  disbursementFileIsNotAvailablePerBank:
    'El archivo de desembolso no está disponible para la cuenta del Banco seleccionado',
  daysPastDue: 'Días de mora',
  transactionCompletedNotificationSent:
    'Transacción completada notificación enviada',
  paymentStarDateRestructuringDate:
    'La fecha de inicio de pago no puede ser inferior a la fecha de re-estructuración.',
  restructuringDateCannotToday:
    'La fecha de reestructuración no puede ser mayor a la de hoy.',
  unpaidInstallmentsRestructuring:
    'No pueden haber cuotas sin pagar al momento de la reestructuración, elimina la cuota generada e inténtalo de nuevo.',
  installmentsPaymentStartDate:
    'No pueden haber cuotas con fecha superior a la fecha de inicio de pago, elimina la cuota generada e inténtalo de nuevo.',
  installmentsGeneratedWithRestructuringDate:
    'No pueden haber cuotas generadas con fecha superior a la fecha de reestructuración, elimina la cuota generada e inténtalo de nuevo.',
  noGracePeriods: 'No se permiten periodos de gracia para este producto',
  maximunNumberRestructurings:
    'No puede exceder el número máximo de reestructuraciones para el producto',
  exceedDaysRestructuring:
    'El plazo de la reestructuración no puede superar los días parametrizados.',
  gracePeriodValuesnotValid: 'Los valores de periodo de gracia no son válidos.',
  confirmPayment: 'Confirmación de Pago',
  areYouSureYouWantToInitiateACHPayment:
    '¿Está seguro que desea iniciar un pago usando ACHQ para este préstamo?',
  thereIsTransactionInPendingStatus: 'Hay una transacción en estado pendiente',
  nextPaymentDate: 'Fecha próximo pago',
  installmentAmount: 'Monto próximo pago',
  externalCode: 'Código del préstamo',
  installmentDate: 'Fecha de cuota',
  payDate: 'Fecha de pago',
  payOffInfo: 'Información de liquidación',
  currentPayment: 'Actual',
  day: 'Día',
  paymentDate: 'Fecha de pago',
  dailyChange: 'Cambio diario',
  sendEmailTo: 'Enviar correo a',
  principalContactEmail: 'Email de contacto principal',
  emailContacts: 'Correo de contacto(s)',
  addEmailContacts: 'Añadir correo de contactos',
  addEmailToSendDocument: 'Agregar correos electrónicos para enviar documento',
  addedEmails: 'Correos agregados',
  addEmail: 'Agregar email',
  sendDocument: 'Enviar documento(s)',
  payOffNotGenerated: 'Liquidación no generada',
  notExistEmailForThisContact: 'No existe email para este contacto',
  unexpectedErrorSendingEmail: 'Error inesperado al enviar correo electrónico',
  payOffSentSuccessfully: 'Liquidación enviada con éxito',
  payOffSentToAllEmailsSuccessfully:
    'Liquidación enviada a todos los correos electrónicos con éxito',
  prepaymentPenalty: 'Cobro por pago anticipado',
  totalAmount: 'Valor total',
  sendStatement: 'Enviar estado de cuenta',
  doesNotExistInstallmentsForThisLoan: 'No existen cuotas para este préstamo',
  noCompleteDataForLoanStatement:
    'No estan completos los datos para generar el estado del préstamo',
  statementSentToAllEmailsSuccessfully:
    'Declaración enviada a todos los correos electrónicos correctamente',
  statementSentSuccessfully: 'Estado de cuenta enviado correctamente',
  validationDisbursWithChargeDiscount:
    'El valor del desembolso no puede ser menor al monto del préstamo ya que el producto cuenta con cargos que se desembolsarán al monto del préstamo.',
  quotaValues: 'Valor adeudado de la cuota',
  paidQuotaValues: 'Valor pagado de la cuota',
  transactions: 'Transacciones',
  paymentMethod: 'Método de pago',
  paymentMethodCapital: 'Método de pago',
  dailyReport: 'Informe diario',
  weeklyReport: 'Informe semanal',
  payments: 'Pagos',
  general: 'General',
  delinquentSince: 'En mora desde',
  amountToBePaid: 'Valor a pagar',
  delinquency: 'Morosidad',
  delinquencyStatus: 'Estado de morosidad',
  delinquencyReport: 'Reporte de morocidad',
  delinquencyReports: 'Reportes de morocidad',
  paymentProcessing: 'Procesamiento de pago',
  extensionActive: 'Extensiones',
  loansChargedOff: 'Castigados',
  loansDeferral: 'Aplazados',
  noExistDelinquencyStatus: 'No existe este reporte de mora',
  noTransactionInformation: 'No hay información de transacciones',
  AUpdateDate: 'Fecha de aprobación',
  PUpdateDate: 'Fecha de inicio',
  RUpdateDate: 'Fecha de rechazado',
  installmentPeriod: 'Período de pago',
  previousPrincipalBalance: 'Saldo de capital anterior',
  currentPrincipalBalance: 'Saldo de capital actual',
  dueDateCapital: 'Fecha de Vencimiento',
  dailyPayments: 'Pagos Diarios',
  of: 'de',
  show: 'Detalles',
  notes: 'Notas',
  addNotes: 'Agregar notas',
  subject: 'Asunto',
  description: 'Descripción',
  noNotes: 'No se encuentran notas registradas',
  noStatements: 'No se encuentran estados registrados',
  loanNoteAddedSuccessfully: 'Nota agregada con éxito',
  noExistLoanNotes: 'No existen notas',
  noteAttachmentNotExist: 'No existe este archivo',
  loanReports: 'Reportes del préstamo',
  loanTerms: 'Condiciones del préstamo',
  loanParticipation: 'Participación en el préstamo',
  stateGuaranteeReport: 'Garantía del Estado',
  borrower: 'Prestatario',
  loanStatus: 'Estado del préstamo',
  chargedOff: 'Castigado',
  chargedOffInformation: 'Información Préstamo Castigado',
  loanNumber: 'Número de préstamo',
  borrowerName: 'Nombre del prestatario',
  firstPaymentDate: 'Fecha del primer pago',
  monthlyPaymentAmount: 'Importe del pago mensual',
  attachmentDeletedSuccessfully: 'Archivo adjunto eliminado correctamente',
  deleteAttachment: 'Borrar archivo adjunto',
  loanNoteDeletedSuccessfully: 'Nota de préstamo eliminada con éxito',
  loanNoteUpdatedSuccessfully: 'Nota de préstamo actualizada correctamente',
  loanNotesLoadedSuccessfully: 'Nota de préstamo cargada con éxito',
  noExistAttachment: 'No existe adjunto',
  startTyping: 'Empezar a escribir',
  deleteNote: 'Borrar nota',
  priorPrincipalBalance: 'Saldo Anterior',
  principalAmount: 'Principal',
  interestAmount: 'Interés',
  penaltyFees: 'Penalización',
  totalPaid: 'Total Pagado',
  paymentReport: 'Informe de Pago',
  showPaymentReport: 'Ver informe de pago',
  reportSentToAllEmailsSuccessfully:
    'Reporte enviado a todos los correos electrónicos',
  reportNotGenerated: 'Informe de pago no generado',
  paymentReportSentSuccessfully: 'Informe de pago enviado correctamente',
  sendPaymentReport: 'Enviar reporte de pagos',
  paymentExecutionDate: 'Fecha de ejecución',
  paymentExecutionDateCapital: 'Fecha de ejecución',
  dateReceived: 'Confirmación',
  noExistPrincipalEmailContact:
    'no existe un correo de contacto principal. Debes configurarlo.',
  areYouSureThisEmailContact:
    '¿Estás seguro de enviar el documento a este correo?',
  fromDate: 'Desde',
  toDate: 'Hasta',
  period: 'Cuota',
  pmtMethod: 'Método de pago',
  fees: 'Cargos',
  originalInvoice: 'Cuota',
  payment: 'Pago',
  downloadExcel: 'Descargar en formato Excel',
  downloadPdf: 'Descargar en formato Pdf',
  sendFileToEmails: 'Enviar archivo a correo(s)',
  executed: 'Ejecutado',
  received: 'Recibido',
  noDataFoundForReport: 'No se encontró información para el reporte',
  fileType: 'Tipo de archivo',
  yourLoan: 'Tu Préstamo',
  charges: 'Cargos',
  totalAmountDue: 'Total adeudado',
  pastDueAmount: 'Total en mora',
  currentlyMonthlyDue: 'Cuota mensual adeudada actualmente',
  installment: 'Cuota',
  linkToPay: 'Link de pago',
  copyLinkAndSendToCustomer: 'Copiar enlace y enviar al cliente',
  copy: 'Copiar',
  thisIsLinkToPay: 'Este es el enlace para pagar',
  loanNotFound: 'Prestamo no encontrado',
  paymentLinkCreatedSuccessfully: 'enlace de pago creado correctamente',
  termConditions: 'Términos y condiciones',
  newPayment: 'Nuevo pago',
  paymentLinkIsInvalid: 'El enlace de pago no es válido',
  disagree: 'En desacuerdo',
  agree: 'De acuerdo',
  areYouSureUseThisService:
    '¿Estás seguro que quieres utilizar este servicio para pagar tu préstamo?',
  paymentLink: 'Link de pago',
  downloadStatement: 'Descargar estado de cuenta',
  downloadPaymentPlan: 'Descargar Plan de Pagos',
  setAutoPaymentInfo: 'Actualizar información de pago',
  setAutoPayment: 'Información de pago',
  autoPaymentInfoUpdatedSuccessful:
    'Información de pago automática actualizada correctamente',
  areYouSureSendLinkThisEmailContact:
    '¿Estás seguro de que quieres enviar el enlace para modificar la información de pago automático a este email?',
  setPaymentInfoSentSuccessfully:
    'Link para actualizar la información de pago automática enviada correctamente',
  sendLinkUpdateAutoPaymentInfo:
    'Enviar enlace para actualizar la Información de pago',
  confirmSetAutoPayment: 'Confirmar actualización de pago automático',
  areYouSureYouWantToUpdateAutoPayment:
    '¿Está seguro de que desea actualizar la información de pago automático?',
  paymentExtension: 'Prórroga de pago',
  dateForPaymentExtension: 'Fecha para la prórroga del pago',
  loanPaymentExtensionAddedSuccessfully:
    'Prórroga del pago del préstamo añadida con éxito',
  extensions: 'Extensiones',
  extensionAttachmentNotExist: 'Archivo adjunto no existente',
  loanPayments: 'Pagos',
  active: 'Activo',
  hasExtension: 'Extensión',
  overdue1To29: 'Mora 1 a 29',
  overdue30To59: 'Mora 30 a 59',
  overdue60To89: 'Mora 60 a 89',
  overdue90OrMore: 'Mora 90 días o más',
  useCurrentAccount: 'Utilizar la cuenta actual',
  noHasPaymentAccountConfigured: 'No tiene cuenta de pago configurada',
  noHasAutoPaymentParametersConfig:
    'No tiene configurados los parámetros de pago automático',
  noPaymentExtensions: 'No se encuentran extensiones de pago registradas',
  isPartialPayment: 'Pago parcial',
  requiredPartialPaymentIsChecked:
    'El pago parcial está marcado, debe ingresar el importe',
  ifPartialPayPaymentAmountMustBeGreaterThatZero:
    'Si es un pago parcial, el importe debe ser mayor a cero.',
  noPaymentExtensionConfigured: 'No hay extensión de pago configurada',
  removeExtension: 'Eliminar extensión',
  loanPaymentExtensionDeletedSuccessfully:
    'Prórroga de pago eliminada con éxito',
  paymentLinkProcess: 'Enlace de pago',
  automaticPayment: 'Pago automático',
  createdByCapital: 'Creado por',
  dateCreated: 'Fecha creación',
  approved: 'Aprobado',
  amountApplied: 'Monto aplicado',
  statements: 'Extractos',
  addStatement: 'Agregar estado',
  loanStatementsLoadedSuccessfully:
    'Extracto(s) de préstamo cargado(s) con éxito',
  loanStatementAttachmentDeletedSuccessfully:
    'Extracto de préstamo eliminado con éxito',
  loanStatementAttachmentNotExist: 'Extracto de préstamo no existente',
  loanStatementSavedSuccessfully: 'Extracto de préstamo guardado correctamente',
  dateClosed: 'Fecha cerrado',
  paymentLinkHasExpired:
    '¡El enlace de pago ha expirado! Contáctanos para un nuevo link de pago.',
  paymentLinkWillBeValid:
    'El enlace de pago tendrá una vigencia de 10 días, pasados estos diez días expirará. Tendrás que contactarnos para un nuevo link de pago.',
  paymentLinkWillExpired: 'El enlace de pago expirará',
  chargedOffAmount: 'Deuda impagada',
  balanceAmount: 'Saldo',
  defaultDate: 'Fecha de impago',
  numberOfPaymentsReceived: 'Número de pagos recibidos',
  numberOfPaymentsMissed: 'Número de pagos no efectuados',
  principalLoanBalance: 'Saldo principal del préstamo en la fecha de impago',
  expensesIncurred: 'Gastos Incurridos',
  repossession: 'Embargo',
  repairs: 'Reparaciones',
  Insurance: 'Seguro',
  otherExpensesIncurred: 'Otro(s) gastos incurridos',
  paymentCollected: 'Pagos Cobrados',
  liquidationProceeds: 'Ingresos de liquidación',
  insuranceRefund: 'Reembolso del seguro',
  otherPaymentCollected: 'Otro(s) pagos cobrados',
  informationUpdatedSuccessfully: 'Información actualizada correctamente',
  informationReportIsEmpty: 'La información del reporte no existe',
  editChargeOffInformation: 'Editar Información del Préstamo Castigado',
  markAsSold: 'Marcar como vendido',
  areYouSureYouWantMarkLoanAsSold:
    '¿Está seguro de que desea marcar el préstamo como vendido?',
  sold: 'Vendido',
  processed: 'Procesado',
  initialPeriod: 'Periodo Inicial',
  beginningPrincipalBalance: 'Saldo Inicial',
  interest: 'Interés',
  installments: 'Total Cuotas',
  endingPeriod: 'Periodo Final',
  endingPrincipalBalance: 'Saldo Final',
  loansReceivable: 'Préstamos a cobrar',
  totalPayments: 'Total Pagos',
  accountsReceivableAging: 'Antigüedad de cuentas por cobrar',
  agingMethod: 'Método de consulta',
  current: 'Actual',
  f: 'Fecha del informe',
  andOver: 'y Más',
  numberOfPeriods: 'Número de periodos',
  periods: 'Periodos',
  amountAppliedCapital: 'Monto aplicado',
  pendingAmountCapital: 'Monto pendiente',
  portfolioBalance: 'Balance de cartera',
  method: 'Método',
  totalPrincipal: 'Total principal',
  totalInterest: 'Total interés',
  totalFees: 'Total cargos',
  paid: 'Pagado',
  loanClosure: 'Cierre del préstamo',
  files: 'Archivos',
  guaranteePaymentRequested: 'Pago de garantía solicitado',
  batchNumber: 'Número de lote',
  purchaseDate: 'Fecha de compra',
  purchaseAmount: 'Valor de la compra',
  premiumAmountPaid: 'Valor de la prima pagado',
  numberOfPaymentsMade: 'Número de pagos realizados',
  paymentsOfPrincipalMade: 'Pagos de principal realizados',
  principalBalanceDue: 'Saldo principal vencido',
  replaceOrMakeWhole: 'Sustituir o completar',
  editLoanParticipation: 'Editar Participación en el Préstamo',
  iBankLoanNumber: 'Número de préstamo de IBank',
  guaranteeAssignmentDate: 'Fecha de asignación de la garantía',
  dateDemandLetterSubmitted: 'Fecha de presentación de la carta de reclamación',
  dateOfLastPaymentReceived: 'Fecha del último pago recibido',
  defaultCalculation: 'Cálculo por defecto',
  principalBalanceDueAtDefault: 'Saldo de capital adeudado en caso de impago',
  repossessionExpenses: 'Gastos de reposesión',
  adjustedPrincipalAmount: 'Importe principal ajustado',
  ninetyDaysOfInterest: '90 días de intereses',
  totalLoss: 'Pérdida total',
  editLoanStateGuarantee: 'Editar garantía estatal del préstamo',
  guaranteedPrincipalAmount: 'Monto principal garantizado',
  interestAmountDue: 'Importe de los intereses adeudados',
  totalGuaranteePaymentRequested: 'Pago total de garantía solicitado',
  amountReceivedFromState: 'Importe recibido del Estado',
  unrecoveredLosses: 'Pérdidas no recuperadas',
  editLoanGuaranteePaymentRequested: 'Editar Pago de Garantía Solicitado',
  guaranteedPrincipalPercent: 'Porcentaje de capital garantizado',
  interestAmountDuePercent: 'Porcentaje de interés vencido',
  reportNotExist: 'Este reporte no existe',
  loanClosureFilesSavedSuccessfully: 'Archivo de cierre guardados con éxito',
  loanClosureFilesNotExist: 'Los archivos de cierre no existen',
  loadedSuccessfully: 'Cargado correctamente',
  informeGenerado: 'Informe generado',
  loanClosureFileDeletedSuccessfully: 'Fichero de cierre borrado con éxito',
  uploadedFiles: 'Archivos subidos',
  deleteFile: 'Borrar archivo',
  notificaciónEnviadaCorrectamente: 'Notificación enviada con éxito',
  readyToChargedOff: 'Listo para ser castigado',
  noFilesUploaded: 'No hay archivo(s) subido(s)',
  sendNotification: 'Enviar Notificación',
  areYouSureYouWantSendNotification:
    '¿Seguro que quieres enviar la notificación?',
  youMustUploadLoanClosureFiles:
    'Debe cargar los archivos de cierre del préstamo',
  'One Dark': 'One Dark',
  Acelera: 'Acelera',
  accounting: 'Contabilidad',
  dashboard: 'Dashboard',
  accountsReceivableSummary: 'Resumen Cuentas por Cobrar',
  yearToDateLoansReceivableSummary:
    'Resumen Préstamos por Cobrar Hasta la Fecha',
  dashboardOverview: 'Vista general',
  numberOfLoansFunded: 'Número de préstamos financiados',
  totalLoanPortfolioValue: 'Valor total de la cartera de préstamos',
  averageLoanAmount: 'Importe promedio del préstamo',
  averageInterestRate: 'Tipo de interés promedio',
  averageOriginationFee: 'Comisión promedio de apertura',
  averageFicoCreditScore: 'Puntuación promedio de crédito FICO',
  loanBuyerOverview: 'Visión general del comprador del préstamo',
  numberOfLoansSold: 'Número de Préstamos Vendidos',
  valueOfLoansSold: 'Valor de los préstamos vendidos',
  averagePremiumAmount: 'Importe promedio de la prima premium',
  expirationDateUpdatedSuccessfully:
    'Fecha de expiración actualizada correctamente',
  insuranceExpirationInformation: 'Información sobre el vencimiento del seguro',
  showInsuranceExpirationInformation:
    'Mostrar información de vencimiento del seguro',
  insuranceCompanyName: 'Compañía de seguros',
  insuranceBrokerName: 'Nombre corredor de seguros',
  insuranceBrokerEmail: 'Correo del corredor de seguros',
  insuranceBrokerPhone: 'Teléfono del corredor de seguros',
  insuranceExpirationDateCapital: 'Fecha de vencimiento del seguro',
  noExistInsuranceExpirationInfo:
    'No existe información sobre la caducidad del seguro',
  insuranceExpirationCreateOrUpdatedSuccessfully:
    'Se ha creado o actualizado correctamente la caducidad del seguro',
  vinCode: 'Código VIN',
  insuranceAmount: 'Monto del seguro',
  hasCollectionActivity:
    'Este contacto tiene notificaciones de cobranza. No puedes eliminarlo',
  inputDateLastInstallment: 'Puede ser la fecha del último plazo pagado',
  numberInstallmentRemainingAddingGracePeriod:
    'Introduzca el número de plazos restantes añadiendo el período o períodos de gracia',
  inputDateInstallmentToBeDeffered:
    'Introduzca la fecha del plazo que se va a aplazar',
  inputNumberOfGracePeriod:
    'Introduzca el número del periodo de gracia a conceder',
  liquidatedCollateralAmount: 'Importe liquidado de la garantía',
  accruedInterestAmount: 'Importe de los intereses devengados',
  downloadDemandLetter: 'Descargar carta de reclamación',
  demandLetterSentToAllEmailsSuccessfully:
    'Carta de reclamación enviada a todos los correos electrónicos correctamente',
  defaultAndRepossession: 'Impago y Reposesión',
  chargedOffDate: 'Fecha de cancelación',
  reasonForChargeOff: 'Motivo de la cancelación',
  repossessionDate: 'Fecha de reposesión',
  repossessionType: 'Tipo de recuperación',
  repossessionCompanyName: 'Nombre de la empresa embargante',
  repossessionCost: 'Coste de la recuperación',
  conditionOfCollateral: 'Estado de la garantía',
  priceDigestValue: 'Valor del resumen del precio',
  liquidationDate: 'Fecha de liquidación',
  liquidationAmount: 'Importe de la liquidación',
  nameOfBuyer: 'Nombre del comprador',
  voluntary: 'Voluntaria',
  involuntary: 'Involuntario',
  good: 'Bueno',
  poor: 'Pobre',
  damaged: 'Dañado',
  totalLost: 'Perdida total',
  attachConditionReport: 'Adjuntar informe de condición',
  attachPriceDigestReport: 'Adjuntar informe de resumen de precios',
  attachBillSaleAndInvoice: 'Adjuntar factura de venta y factura',
  notSelected: 'No seleccionado',
  noExistDefaultAndRepossession: 'No existen Impago y Reposesión',
  informationSavedSuccessfully: 'Información guardada con éxito',
  attachmentsTo: 'Adjunto(s) a ',
  editDefaultAndRepossession: 'Editar Impago y Reposesión',
  loanSaleDetails: 'Detalles de la Venta',
  amountPurchased: 'Cantidad comprada',
  continuePaymentOrMakeWhole: 'Continuar con el pago o recuperar',
  continuePayment: 'Continuar pago',
  makeWhole: 'Reintegrar',
  editLoanSaleDetails: 'Editar Detalles de Venta',
  investorName: 'Nombre Inversor',
  getDataFromOpEx: 'Obtener datos de OpEx',
  getSaleDetailsFromExternalService:
    'Obtener detalles de venta del servicio externo',
  loanHasNotBeenSoldYet: 'El préstamo no se ha vendido todavía',
  stateGuaranteeNotFound: 'No se ha encontrado la garantía estatal',
  dateDemandLetterSubmittedNotExist:
    'La fecha de presentación de la carta de reclamación no existe',
  dateDemandLetterIsLessThanDateLastPaymentMade:
    'La fecha de la carta de reclamación es menor que la fecha del último pago realizado',
  getSaleDetails: 'Obtener detalles de venta',
  dateOfLastPaymentMadeToInvestor:
    'Fecha del último pago realizado al inversor',
  investor: 'Inversor',
  investorAddress: 'Direccion Inversor',
  investorEmail: 'Email Inversor',
  downloadDraftNoticeDefaultInvestorV1:
    'Descargar borrador de notificación de impago de préstamo al inversor V1',
  dateOfNoticeLoanDefaultToInvestor:
    'Fecha de notificación del impago del préstamo al inversor',
  sendDraftDocumentToEmails:
    'Enviar borrador de documento a correo(s) electrónico(s)',
  signOffOnAmountsAndCalculations:
    'Dar el visto bueno a los importes y cálculos',
  areYouSureYouWantToSignOffOnAmountsAndCalculations:
    '¿Seguro que quiere firmar en Importes y Cálculos?',
  saleDetailsNotFound: 'Detalles de venta no encontrados',
  dateOfLastPaymentMadeToInvestorNotExist:
    'Fecha del último pago realizado al inversor no existe',
  noExistNotification: 'Notificación no existente',
  noticeLoanDefaultSentToAllEmailsSuccessfully:
    'Aviso de préstamo impagado enviado a todos los correos electrónicos con éxito',
  fileSavedSuccessfully: 'Archivo guardado correctamente',
  sendNoticeLoanDefaultToInvestor:
    'Enviar notificación de impago de préstamo al inversor',
  sendNoticeToInvestor: 'Enviar aviso al inversor',
  areYouSureYouWantToSendNoticeToInvestor:
    '¿Está seguro de que desea enviar notificación al inversor?',
  investorEmailIsNotValid: 'El correo electrónico del inversor no es válido',
  noticeLoanDefaultSentSuccessfully:
    'Aviso de impago de préstamo enviado correctamente',
  emailSentToServicingAndFileSavedSuccessfully:
    'Email enviado a servicing y archivo guardado con éxito',
  fileSavedSuccessfullyErrorSendingServicingEmail:
    'Archivo guardado con éxito pero, con un error al enviar el email a Servicing',
  downloadDraftDemandLetter: 'Descargar el Borrador de la Carta de Demanda',
  signOnDemandLetter: 'Firmar la Carta de Reclamación',
  areYouSureYouWantToSignOnDemandLetter:
    '¿Seguro que quiere firmar en la Carta de Reclamación?',
  sendSignedDocumentToEmails:
    'Enviar documento firmado a correo(s) electrónico(s)',
  addEmailsToSendDocumentAsToAndCc:
    'Agregar correo(s) electrónico(s) para enviar documento(s) Para y Cc',
  addEmailAsTo: 'Agregar correo(s) electrónico(s) como Para',
  addedEmailsAsTo: 'Correo(s) electrónico(s) agregados como Para',
  emailAsTo: 'Correo electrónico como Para',
  addEmailAsCc: 'Agregar correo(s) electrónico(s) como Cc',
  addedEmailsAsCc: 'Correo(s) electrónico(s) agregados como Cc',
  emailAsCc: 'Correo electrónico como Cc',
  insuranceReport: 'Seguro',
  expirationDate: 'Fecha de vencimiento',
  fortyFiveDays: '45 días',
  thirtyDays: '30 días',
  tenDays: '10 días',
  zeroDays: '0 día',
  insuranceNoteAddedSuccessfully: 'Nota de seguro añadida con éxito',
  noExistInsuranceNote: 'No existe nota de seguro',
  insuranceNoteUpdatedSuccessfully: 'Nota de seguro actualizada con éxito',
  insuranceNoteDeletedSuccessfully: 'Nota de seguro eliminada con éxito',
  insuranceNoteAttachmentNotExist: 'No existe este archivo',
  insurance: 'Seguros',
  addInsuranceNote: 'Añadir nota de seguro',
  deleteInsuranceNote: 'Borrar nota de seguro',
  noInsurancesNotes: 'No se encuentran notas de seguros registradas',
  expired: 'Vencido',
  sent: 'Enviado',
  buyerInformation: 'Información del comprador',
  state: 'Estado',
  zip: 'Código postal',
  vehicleCollateralDetails: 'Detalles de la garantía del vehículo',
  vin: 'Vin',
  licensePlate: 'Matrícula',
  saleAmount: 'Importe de venta',
  saleDate: 'Fecha de venta',
  billOfSale: 'Factura de venta',
  downloadDraftBillOfSale: 'Descargar Borrador de Factura de Venta',
  editBillOfSale: 'Editar Factura de Venta',
  downloadDraftInvoice: 'Descargar Borrador Factura',
  documentsSentToAllEmailsSuccessfully:
    'Documento(s) enviado(s) a todos los correos electrónicos correctamente',
  billOfSaleNotFound: 'Documento de venta no encontrado',
  emailSubject: 'Asunto',
  emailBody: 'Cuerpo',
  generateAndSend: 'Generar y Enviar Documentos',
  areYouSureYouWantToSendFiles: '¿Seguro que quieres enviar los archivos?',
  confirmSendFilesToEmails: 'Confirma Enviar Archivos a Email(s)',
  created: 'Creado',
  updated: 'Actualizado',
  downloadFilteredReport: 'Descargar informe filtrado',
  downloadActiveInsuranceReport: 'Descargar informe de seguros activos',
  insPolicyOnFile: 'Póliza archivada',
  buyer: 'Comprador',
  invoiceNumber: 'Número de factura',
  invoice: 'Factura',
  collateralReport: 'Garantía',
  downloadAllCollateralReport: 'Descargar informe de todas las garantías',
  coi: 'COI',
  coi: 'COI',
  paymentCycle: 'Ciclo de pago',
  cycle1st: '1ro',
  cycle15th: '15vo',
  downloadDraftNoticeDefaultInvestor:
    'Descargar borrador de notificación de impago de préstamo al inversor V2',
  version: 'Versión',
  downloadDraftNoticeDefaultInvestor:
    'Descargar borrador de notificación de impago de préstamo al inversor',
  defaultedLoansReport: 'Préstamos impagados',
  ibank: 'IBank',
  submitted: 'Presentado',
  initialLoan: 'Préstamo inicial',
  defaultedBalance: 'Saldo impagado',
  principalPaid: 'Principal pagado',
  pmtsMade: 'Pagos realizados',
  defaulted: 'Impagado',
  equipment: 'Equipo',
  initial: 'Inicial',
  liquidation: 'Liquidación',
  monthOfDefault: 'Mes del impago',
  daysToReceiveStateGuarantee: 'Número días para garantía del Estado',
  downloadAllLoansClosureReport:
    'Descargar informe de todos los préstamos cerrados',
  eQLoanListReport: 'Préstamos vendidos',
  contract: 'Contrato',
  customerName: 'Nombre del cliente',
  financing: 'Financiación',
  downPmt: 'Pago inicial',
  closing: 'Cierre',
  date: 'Fecha',
  int: 'Int',
  int: 'Int',
  batch: 'Lote',
  monthlyPmt: 'Pago Mensual',
  loanSold: 'Préstamo Vendido',
  loanSales: 'Préstamo Vendido',
  date: 'Fecha',
  pd: 'Pd',
  statementCycle: 'Ciclo del extracto',
  percentSold: 'Porcentaje vendido',
  premiumFee: 'Comisión de prima',
  servicingFee: 'Comisión de servicio',
  acqEffective: 'Adq Efectivo',
  balance: 'Saldo',
  premium: 'Premium',
  loanPurchase: 'Compra de préstamo',
  investment: 'Inversión',
  mthPmt: 'Pago mensual',
  irr: 'TIR',
  calculateIrr: 'Calcular TIR',
  dummy: 'Dummy',
  periodPurchased: 'Periodo comprado',
  successfullyButErrorInOpex: 'Con éxito, pero hubo un error en Opex',
  transactionDetails: 'Detalles de la Transacción',
  installmentDetails: 'Detalles de la cuota',
  termDate: 'Fecha del préstamo',
  filteredBy: 'Filterado por',
  amountDue: 'Importe a pagar',
  dateNotice: 'Fecha aviso',
  stateGuaranteeLoans: 'Préstamos IBank',
  numberOfDefaultedLoans: 'Número de préstamos impagados',
  defaultRate: 'Tasa de impago',
  ibankAmount: 'Importe recibido IBank',
  calCapLoans: 'Préstamos CalCAP SB',
  noRegistered: 'No registrado',
  showLoansFunded: 'Ver préstamos financiados',
  showDefaultedLoans: 'Ver préstamos impagados',
  showLoansSold: 'Ver préstamos vendidos',
  daysLate: 'Días retraso',
  creditEnhancementType: 'Tipo de mejora crediticia',
  stateGuarantee: 'IBank',
  calCap: 'CalCAP SB',
  ineligible: 'No Elegible',
  areYouSureYouWantMarkLoanAsIneligible:
    '¿Está seguro de que desea marcar el préstamo como no elegible?',
  markAsIneligible: 'Marcar como no elegible',
  loanMarkedAsIneligible: 'préstamo marcado como no elegible',
  downloadLoanClosureMemo: 'Descargar nota de cierre de préstamo',
  searchLoans: 'Buscar préstamos',
  portfolioLoans: 'Cartera de préstamos',
  activeLoans: 'Préstamos activos',
  notifyAccounting: 'Notificar a Contabilidad',
  sendNotificationToAccounting: 'Enviar Notificación a Contabilidad',
  saveToSend: 'Guardar para enviar',
  sendNoticeDefaultLackInsurance:
    'Enviar notificación de impago por falta de seguro',
  downloadNoticeDefaultLackInsurance:
    'Descargar notificación de impago por falta de seguro',
  emailSentAndSavedSuccessfully:
    'Correo electrónico enviado y guardado correctamente',
  fileSavedSuccessfullyErrorSendingEmail:
    'Archivo guardado con éxito, con error al enviar el correo',
  areYouSureYouWantToSendNoticeDefaultLackInsurance:
    '¿Está seguro de que desea enviar el aviso de notificación de impago por falta de seguro?',
  voluntaryCollateralRelease: 'Liberación Voluntaria de Garantías',
  dateInformedVoluntaryReturn: 'Fecha informada de la devolución voluntaria',
  sendVoluntaryCollateralReleaseLetter:
    'Enviar carta de liberación voluntaria de garantía',
  downloadVoluntaryCollateralReleaseLetter:
    'Descargar carta de liberación voluntaria de garantía',
  areYouSureYouWantToSendVoluntaryCollateralReleaseLetter:
    '¿Está seguro de que desea enviar la carta de liberación voluntaria de garantías?',
  noticeLoanDefault: 'notificación de impago de préstamo',
  categoryFile: 'Categoría de los ficheros',
  uploadedByUser: 'Subido por el usuario',
  demandLetter: 'Carta de demanda',
  watchList: 'Lista de vigilancia',
  monthlyPaymentDueDate: 'Fecha de vencimiento del pago mensual',
  totalPastDueAmount: 'Importe total vencido',
  call: 'Llamar',
  sendNotifications: 'Enviar notificaciones',
  amountInProcess: 'Monto en curso',
  signOff: 'Aprobar',
  nsf: 'NSF',
  borrowerProfileData: 'Datos del perfil del prestatario',
  ficoScore: 'Puntuación FICO',
  minFicoScore: 'Puntuación FICO mínima',
  maxFicoScore: 'Puntuación FICO máxima',
  yearsInBusiness: 'Años en el negocio',
  minYearsInBusiness: 'Años mínimos en el negocio',
  maxYearsInBusiness: 'Años máximos en el negocio',
  downPaymentPercentage: 'Porcentaje de pago inicial',
  minDownPaymentPercentage: 'Porcentaje mínimo de pago inicial',
  maxDownPaymentPercentage: 'Porcentaje máximo de pago inicial',
  totalMonthlyBankDeposits: 'Depósitos bancarios mensuales totales',
  minTotalMonthlyBankDeposits: 'Depósitos bancarios mensuales totales máximos',
  maxTotalMonthlyBankDeposits: 'Depósitos bancarios mensuales totales máximos',
  numberOfPreviousLoans: 'Número de préstamos anteriores',
  minNumberOfPreviousLoans: 'Número mínimo de préstamos anteriores',
  maxNumberOfPreviousLoans: 'Número máximo de préstamos anteriores',
  typeOfBusiness: 'Tipo de entidad comercial',
  loanCharacteristics: 'Características del préstamo',
  loanAmount: 'Importe del Préstamo',
  minLoanAmount: 'Importe mínimo del préstamo',
  maxLoanAmount: 'Importe máximo del préstamo',
  loanTerm: 'Plazo del préstamo (meses)',
  minLoanTerm: 'Plazo mínimo del préstamo',
  maxLoanTerm: 'Plazo máximo del préstamo',
  interestRate: 'Interés',
  minInterestRate: 'Tipo de interés mínimo',
  maxInterestRate: 'Tipo de interés máximo',
  monthlyPaymentAmount: 'Importe del pago mensual',
  minMonthlyPaymentAmount: 'Importe mínimo del pago mensual',
  maxMonthlyPaymentAmount: 'Importe máximo del pago mensual',
  loanToValueRatioAtOrigination: 'Relación préstamo-valor en origen',
  minLoanToValueRatioAtOrigination: 'Relación mínima entre préstamo y valor',
  maxLoanToValueRatioAtOrigination: 'Relación máxima entre préstamo y valor',
  originationFeeCharged: 'Comisión de apertura cobrada',
  minOriginationFeeCharged: 'Mínima comisión de apertura cobrada',
  maxOriginationFeeCharged: 'máxima comisión de apertura cobrada',
  timeFromApplicationToFunding:
    'Tiempo desde la solicitud hasta la financiación',
  minTimeFromApplicationToFunding:
    'Tiempo mínimo desde la solicitud hasta la financiación',
  maxTimeFromApplicationToFunding:
    'Tiempo máximo desde la solicitud hasta la financiación',
  collateralInformation: 'Información colateral',
  vehicleType: 'Tipo de vehículo',
  vehicleAgeAtOrigination: 'Edad del vehículo',
  minVehicleAgeAtOrigination: 'Edad mínima del vehículo',
  maxVehicleAgeAtOrigination: 'Edad máxima del vehículo',
  vehicleMileageAtOrigination: 'Kilometraje del vehículo',
  minVehicleMileageAtOrigination: 'Kilometraje mínimo del vehículo',
  maxVehicleMileageAtOrigination: 'Kilometraje máximo del vehículo',
  vehicleManufacturer: 'Fabricante del vehículo',
  originalPurchasePrice: 'Precio de compra original',
  minOriginalPurchasePrice: 'Precio de compra original mínimo',
  maxOriginalPurchasePrice: 'Precio de compra original máximo',
  typeOfHauling: 'Tipo de transporte',
  paymentPerformance: 'Rendimiento del pago',
  numberOfLatePayments: 'Número de pagos atrasados (de por vida)',
  minNumberOfLatePayments: 'Número mínimo de pagos atrasados',
  maxNumberOfLatePayments: 'Número máximo de pagos atrasados',
  averageDaysLatePerPayment: 'Promedio de días de retraso por pago',
  minAverageDaysLatePerPayment: 'Promedio mínimo de días de retraso por pago',
  maxAverageDaysLatePerPayment: 'Promedio máximo de días de retraso por pago',
  numberOfNSF: 'Número de casos de fondos insuficientes',
  minNumberOfNSF: 'Número mínimo de fondos insuficientes',
  maxNumberOfNSF: 'Número máximo de fondos insuficientes',
  numberOfLoanExtensionsRequested: 'Número de extensiones de pago solicitadas',
  minNumberOfLoanExtensionsRequested:
    'Número mínimo de extensiones de pago solicitadas',
  maxNumberOfLoanExtensionsRequested:
    'Número máximo de extensiones de pago solicitadas',
  numberOfLoanExtensionsGranted: 'Número de extensiones de pago concedidas',
  minNumberOfLoanExtensionsGranted:
    'Número mínimo de extensiones de pago concedidas',
  maxNumberOfLoanExtensionsGranted:
    'Número máximo de extensiones de pago concedidas',
  numberOfLoanDeferralsRequested:
    'Número de aplazamientos de préstamo solicitados',
  minNumberOfLoanDeferralsRequested:
    'Número mínimo de aplazamientos de préstamo solicitados',
  maxNumberOfLoanDeferralsRequested:
    'Número máximo de aplazamientos de préstamo solicitados',
  numberOfLoanDeferralsGranted:
    'Número de aplazamientos de préstamo concedidos',
  minNumberOfLoanDeferralsGranted:
    'Número mínimo de aplazamientos de préstamo concedidos',
  maxNumberOfLoanDeferralsGranted:
    'Número máximo de aplazamientos de préstamo concedidos',
  longestConsecutiveStreak: 'Racha consecutiva más larga',
  minLongestConsecutiveStreak: 'Racha consecutiva mínima',
  maxLongestConsecutiveStreak: 'Racha consecutiva máxima',
  numberOfInsuranceLapses: 'Número de caducidades del seguro',
  minNumberOfInsuranceLapses: 'número mínimo de caducidades del seguro',
  maxNumberOfInsuranceLapses: 'Número máximo de caducidades del seguro',
  numberOfAchAutoDebitFailures: 'Número de fallos de débito automático ACH',
  minNumberOfAchAutoDebitFailures:
    'Número mínimo de fallos de débito automático',
  maxNumberOfAchAutoDebitFailures:
    'Número máximo de fallos de débito automático',
  dryVan: 'Furgoneta seca',
  flatbed: 'Plataforma',
  reefer: 'Máscara',
  riskManagement: 'Gestión de riesgos',
  createRiskManagement: 'Crear filtro de gestión de riesgos',
  editRiskManagement: 'Editar filtro de gestión de riesgos',
  newRiskManagementFilter: 'Nuevo filtro de gestión de riesgos',
  riskManagementFilterToDelete: 'Filtro de gestión de riesgos a eliminar',
  areYouSureYouWantToDeleteTheRiskManagementFilter:
    '¿Está seguro de que desea eliminar este filtro?',
  riskManagementFilterSavedOrUpdatedSuccessfully:
    'Filtro de gestión de riesgos guardado o actualizado correctamente',
  riskManagementFilterDeletedSuccessfully:
    'Filtro de gestión de riesgos eliminado correctamente',
  riskManagementFilterNoFound:
    'No se ha encontrado el filtro de gestión de riesgos',
  totalMonthlyBankDepositsTable: 'Depósitos bancarios mensuales',
  numberOfNSFTable: 'Número mínimo de fondos insuficientes',
  default: 'Por defecto',
  loanManagementReports: 'Informes de gestión de préstamos',
  interestPayment: 'Intereses de pago',
  downloadInterestPaymentDocument: 'Descargar documento de pago de intereses',
  sendInterestDocumentToPrincipalContact:
    'Enviar documento de intereses a contacto principal',
  interestPaymentDocumentSentSuccessfully:
    'Documento de pago de intereses enviado con éxito',
  interestPaymentDocumentSentToAllEmailsSuccessfully:
    'Documento de pago de intereses enviado a todos los correos electrónicos con éxito',
  historicalDataTape: 'Cinta de datos históricos',
  folderLink: 'Enlace a la carpeta',
  downloadReport: 'Descargar reporte',
};
