export const navBarEs = {
  access: 'Access',
  'Create product': 'Crear producto',
  Dashboard: 'Tablero',
  debtCollections: 'Cobranzas',
  Invoices: 'Facturas',
  'List customers': 'Lista de clientes',
  'List invoices': 'Lista de facturas',
  'List loans': 'Lista de préstamos',
  'List orders': 'Lista de ordenes',
  'List originators': 'Lista de originadores',
  'List products': 'Lista de productos',
  LoanRequest: 'Solicitud de préstamo',
  Loans: 'Buscar préstamos',
  'New originator': 'Nuevo originador',
  Orders: 'Ordenes',
  Originators: 'Originadores',
  Products: 'Productos',
  Request: 'Solicitudes',
  'View invoice': 'Ver factura',
  'View order': 'Ver orden',
  thereAreNoNotifications: 'Ninguna',
  loanManagementFilters: 'Filtros de gestión de préstamos',
};

export const navBarEn = {
  access: 'Accesos',
  'Create product': 'Create Product',
  Dashboard: 'Dashboard',
  debtCollections: 'Debt Collections',
  Invoices: 'Invoices',
  'List customers': 'List of Customers',
  'List invoices': 'List of Invoices',
  'List loans': 'List of Loans',
  'List orders': 'List of Orders',
  'List originators': 'List Originators',
  'List products': 'List of Products',
  LoanRequest: 'Loan Application',
  Loans: 'Search Loans',
  'New originator': 'New Originator',
  Orders: 'Orders',
  Originators: 'Originators',
  Products: 'Products',
  Request: 'Applications',
  'View invoice': 'View Invoice',
  'View order': 'View Order',
  thereAreNoNotifications: 'None',
  loanManagementFilters: 'Loan Management Filters',
};
