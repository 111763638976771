export const debtCollectionsFactorRulesListEn = {
  'Campaign active': 'Campaign Active',
  campaignActive: 'Campaign Active',
  campaignMessageEmail: 'Campaign Message Email',
  campaignMessageSMS: 'Campaign Message SMS',
  createFilter: 'Create Filter',
  customerIdentification: 'Enter Borrower Identification',
  'Days from last factor': 'Days from Last Attempt',
  daysBeforeOverdue: 'Days Before Overdue',
  daysFromLastFactor: 'Days from Last Attempt',
  Description: 'Description',
  editFilter: 'Edit Filter',
  factorFilter: 'Borrowers Filter',
  filter: 'Filter',
  factorRuleCreatedSuccessfully: 'Filter created successfully',
  factorFilters: 'Debt Collections Filters',
  factorRuleUpdatedSuccessfully: 'Filter updated successfully',
  filterBorrowers: 'Filter Borrowers',
  'From overdue days': 'From Overdue Days',
  fromOverdueDays: 'From Overdue Days',
  legalProcess: 'Legal Process',
  newFilter: 'New Borrowers Filter',
  newFilterBorrowers: 'New Borrowers Filter',
  paymentAgreement: 'Payment Agreement',
  filters: 'Filters',
  sendDebtCollectionRule: 'A debt collection filter has not been selected',
  'To overdue days': 'To Overdue Days',
  toOverdueDays: 'To Overdue Days',
  newLoanManagementFilters: 'New Loan Management Filters',
};

export const debtCollectionsFactorRulesListEs = {
  'Campaign active': 'Campaña activa',
  campaignActive: 'Campaña activa',
  campaignMessageEmail: 'Mensaje de campaña Email',
  campaignMessageSMS: 'Mensaje de campaña SMS',
  createFilter: 'Crear filtro de cobranza',
  customerIdentification: 'Ingrese la identificación del cliente',
  'Days from last factor': 'Días desde última gestión',
  daysBeforeOverdue: 'Días antes de mora',
  daysFromLastFactor: 'Días desde última gestión',
  Description: 'Descripción',
  editFilter: 'Editar filtro de cobranza',
  factorFilter: 'Filtro de cobranza',
  filter: 'Filtrar',
  factorRuleCreatedSuccessfully: 'Filtro de cobranza creado correctamente',
  factorFilters: 'Filtro de cobranzas',
  factorRuleUpdatedSuccessfully: 'Filtro de cobranza actualizado correctamente',
  filterBorrowers: 'Filtrar deudores',
  'From overdue days': 'Días de mora desde',
  fromOverdueDays: 'Días de mora desde',
  legalProcess: 'Proceso legal',
  newFilter: 'Nuevo filtro',
  newFilterBorrowers: 'Nuevo filtro de deudores',
  paymentAgreement: 'Acuerdo de pago',
  filters: 'Filtros',
  sendDebtCollectionRule: 'No se ha seleccionado un filtro de cobranza',
  'To overdue days': 'Días de mora hasta',
  toOverdueDays: 'Días de mora hasta',
  newLoanManagementFilters: 'Nuevo filtro de gestión de préstamos',
};
